<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
>
  <ng-template #modalTitle>Change MHD</ng-template>

  <ng-template #modalContent>
    <label>EAN</label>
    <app-search (searchChanged)="onSearchChanged($event)" />
    <nz-divider />
    <nz-spin [nzSpinning]="isLoading()">
      <nz-row [nzGutter]="16" *ngIf="articleVariant">
        <nz-col [nzSpan]="8">
          <nz-statistic [nzValue]="articleVariant.fullName" nzTitle="Name" />
        </nz-col>
        <nz-col [nzSpan]="8">
          <nz-statistic [nzValueTemplate]="currentMhdValue" nzTitle="Aktuell MHD">
            <ng-template #currentMhdValue>
              {{ articleVariant.mhd | dateFormat }}
            </ng-template>
          </nz-statistic>
        </nz-col>
        <nz-col [nzSpan]="8">
          <nz-statistic [nzValueTemplate]="newMhdValue" nzTitle="Neue MHD">
            <ng-template #newMhdValue>
              <nz-date-picker
                style="width: 200px;"
                [nzFormat]="dateFormat"
                [(ngModel)]="newMhd"
              />
            </ng-template>
          </nz-statistic>
        </nz-col>
      </nz-row>
    </nz-spin>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()" [disabled]="isLoading()">Schließen</button>
    <button nz-button nzType="primary" (click)="saveNewMhd()" [nzLoading]="isLoading()">Speichern</button>
  </ng-template>
</nz-modal>
