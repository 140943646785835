import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '../../../core/services';
import { AddEditFormGeneric } from '../../../ui/generics';
import { IArticleVariant } from '../../../core/interfaces';
import { ArticlesService } from '../core/ArticlesService';
import { ArticlesServiceErrorResolverService } from '../core/error-resolvers/articles-service-error-resolver.service';
import { map } from 'rxjs/operators';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { Observable } from 'rxjs';
import { OwnProductionService } from '../../own-production/core/OwnProductionService';
import { ToolsService } from '../../../core/services/ToolsService';
import { IGiftboxFormula } from '../../giftbox-production/core/interfaces/IGiftboxFormula';
import { ArticleVariantConfigurationsService } from '../core/ArticleVariantConfigurationsService';
import { GiftboxesService } from '../../giftbox-production/core/services/GiftboxesService';

@Component({
  selector: 'app-add-edit-variant-form',
  templateUrl: './add-edit-variant-form.component.html',
  styleUrls: ['./add-edit-variant-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditVariantFormComponent extends AddEditFormGeneric<IArticleVariant> implements OnInit {

  @Input() articleId: number;

  @Input() set isGiftbox(val) {
    this.form.get('isGiftbox').patchValue(val);
  }

  @Input() set editObject(val: IArticleVariant | null) {
    this.isEdit = val !== null;

    if (val) {
      super.beforeLoadForm();
      this.loadArticleVariantConfigs(val.name || '', results => {
        this.articleVariantConfigurations = results;
        this.loading = true;
        this._service.getVariantById(val.id).subscribe(res => {
          this._editObject = res;
          this.loading = false;
          this.form.patchValue(res);

          if (this._editObject.articleVariantConfigurationId) {
            this.form.get('articleVariantConfigurationId').patchValue(this._editObject.articleVariantConfigurationId.toString());
          }

          if (this.form.get('isGiftbox').value) {
            this._giftboxesService.getFormulaGiftBox(this._editObject.id).subscribe(value => {
              this.selectedGiftboxArticles = value;
              this._cdr.detectChanges();
            });
          }

          this._cdr.detectChanges();
        });
      });
    }
  }

  public selectedGiftboxArticles: IGiftboxFormula[] = [];
  public articleVariantConfigurations: ISimpleListItem[] = [];
  public giftboxFormulaArticles$: Observable<IGiftboxFormula[]>;
  public gitboxSearch = '';

  private _variant: IArticleVariant;

  constructor(
    protected _fb: FormBuilder,
    protected _service: ArticlesService<IArticleVariant>,
    protected _notifyService: NotificationService,
    protected _errorResolver: ArticlesServiceErrorResolverService,
    private _ownProductionService: OwnProductionService,
    private _cdr: ChangeDetectorRef,
    private readonly _toolsService: ToolsService,
    private readonly _articleVariantConfigurationsService: ArticleVariantConfigurationsService,
    private readonly _giftboxesService: GiftboxesService,
  ) {
    super(_fb, _service, _notifyService, _errorResolver);
    this.form = this._fb.group({
      articleVariantConfigurationId: ['', Validators.required],
      name: [''],
      orderNumber: ['', Validators.required],
      ean: [null],
      weight: [0],
      purchaseUnit: [0],
      packUnit: [null],
      stockMin: [0],
      isDefault: [false],
      isGiftbox: [false],
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.giftboxFormulaArticles$ = this._service.getArticlesVariants('', true).pipe(
      map(this.castSimpleListToGiftbox)
    );
  }

  public submitForm(): void {
    this.isSubmitted = true;
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      }
    }

    if (!this.form.valid) {
      this.formFinished.emit(false);
      return;
    }

    this._variant = {
      ...this._editObject,
      ...this.form.value,
    };
    this._variant.ean = this._variant.ean ? this._variant.ean.toString() : this._variant.ean;
    this._variant.articleVariantConfigurationId = parseInt(
      this._variant.articleVariantConfigurationId.toString(),
      10
    );

    !this.isEdit ? this.saveNew() : this.saveEdit();
  }

  public saveNew(): void {
    this._variant.articleId = this.articleId;
    this._service.createArticleVariant(this._variant).subscribe(() => {
      this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt erstellt`);
      this.formFinished.emit(true);
    }, err => {
      this.parseError(err);
      this.formFinished.emit(false);
    });
  }

  public saveEdit() {
    this._service.updateArticleVariant(this._variant, this._editObject.id).subscribe(() => {
      this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt aktualisiert.`);
      this.formFinished.emit(true);
    }, err => {
      this.parseError(err);
      this.formFinished.emit(false);
    });
  }

  public removeGiftboxFromList(i: number): void {
    this.selectedGiftboxArticles = this.selectedGiftboxArticles.filter((item, id) => id !== i);
    this._cdr.detectChanges();
  }

  public addGiftboxToList(obj: IGiftboxFormula): void {
    obj.id = -1;
    this.selectedGiftboxArticles = [...this.selectedGiftboxArticles, obj];
    this._cdr.detectChanges();
  }

  public searchGiftboxArticles(phrase: string): void {
    this.gitboxSearch = phrase;
    this.giftboxFormulaArticles$ = null;
    this.giftboxFormulaArticles$ = this._service.getArticlesVariants(phrase, true).pipe(
      map(this.castSimpleListToGiftbox)
    );
  }
  public addVariantName(value: string): void {
    this._articleVariantConfigurationsService.createArticleVariantConfiguration(value).subscribe(() => {
      this.loadArticleVariantConfigs(value);
    });
  }

  public nameSearch(value: string): void {
    if ((this._editObject && value !== '') || !this._editObject) {
      this.loadArticleVariantConfigs(value);
    }
  }

  public nameChanged(): void {
    if (this.form.get('articleVariantConfigurationId').value) {
      this._articleVariantConfigurationsService.getArticleVariantConfigurationById(
        parseInt(this.form.get('articleVariantConfigurationId').value, 10)
      ).subscribe(res => this.form.get('name').patchValue(res.name));
    } else {
      this.loadArticleVariantConfigs('');
    }
  }

  protected afterLoadForm() {
    super.afterLoadForm();

    this.form.disable();
  }

  private loadArticleVariantConfigs(search = '', cb: (results: ISimpleListItem[]) => void = results => {
    this.articleVariantConfigurations = results;
    this._cdr.detectChanges();
  }): void {
    this._articleVariantConfigurationsService.getArticleVariantConfigurations(search).subscribe(cb.bind(this));
  }

  private castSimpleListToGiftbox(items: ISimpleListItem[]): IGiftboxFormula[] {
    return items.map(({ id, value }) => {
      return {
        ingredientArticleVariantId: parseInt(id, 10),
        fullName: value
      };
    });
  }
}
