import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ModalGeneric } from '../../../ui/generics';
import { IFlourPackingListItem } from '../../flour/core/interfaces';

@Component({
  selector: 'app-delivery-note-error-modal',
  templateUrl: './delivery-note-error-modal.component.html',
  styleUrl: './delivery-note-error-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryNoteErrorModalComponent extends ModalGeneric {

  public deliveryResponse: IFlourPackingListItem;

  public constructor (
    protected readonly cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  public showModal(object: IFlourPackingListItem): void {
    super.showModal(object);
    this.deliveryResponse = object;
    this.cdr.detectChanges();
  }
}
