<nz-spin [nzSpinning]="loading">
  <nz-row>
    <form nz-form [formGroup]="form" (ngSubmit)="submitForm()" style="width: 100%">
      <nz-col>
        <nz-form-item>
          <nz-form-label nzFor="originStore" nzRequired [nzSm]="3" [nzXs]="24">
            <span>Ursprungsspeicher</span>
          </nz-form-label>
          <nz-form-control [nzSm]="3" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <nz-select formControlName="originStoreId" (ngModelChange)="originStoreChanged()">
              <nz-option *ngFor="let store of (storesList$ | async)" [nzLabel]="store.value" [nzValue]="store.id"></nz-option>
            </nz-select>
          </nz-form-control>

          <nz-form-label nzFor="targetStoreId" nzRequired [nzSm]="3" [nzXs]="24">
            <span>Zielspeicher</span>
          </nz-form-label>
          <nz-form-control [nzSm]="3" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <nz-select formControlName="targetStoreId" (ngModelChange)="reloadArticles()">
              <nz-option *ngFor="let store of targetStoresList" [nzLabel]="store.value" [nzValue]="store.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </nz-col>

      <nz-col>
        <nz-form-item>
          <nz-form-label nzFor="worker" nzRequired [nzSm]="3" [nzXs]="24" >
            <span>Mitarbaiter</span>
          </nz-form-label>
          <nz-form-control [nzSm]="3" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <nz-select formControlName="worker">
              <nz-option *ngFor="let user of (usersList$ | async)" [nzLabel]="user.value" [nzValue]="user.id"></nz-option>
            </nz-select>
          </nz-form-control>

          <nz-form-label nzFor="worker" nzRequired [nzSm]="3" [nzXs]="24" >
            <span>Liefertermin</span>
          </nz-form-label>
          <nz-form-control [nzSm]="3" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <nz-date-picker
              [nzFormat]="dateFormat"
              formControlName="deliveryDate"
              [nzDisabledDate]="disableDatesBeforeNow"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </nz-col>

      <nz-col>
        <nz-form-item>
          <nz-form-label nzFor="status" [nzSm]="3" [nzXs]="24">
            <span>Status</span>
          </nz-form-label>
          <nz-form-control [nzSm]="3" [nzXs]="24">
            <nz-select formControlName="status" id="status" nzDisabled>
              <nz-option *ngFor="let type of statusTypes | keyvalue" [nzLabel]="type.value.toString()" [nzValue]="type.key"></nz-option>
            </nz-select>
          </nz-form-control>

          <nz-form-label nzFor="isPriority" [nzSm]="3" [nzXs]="24">
            <span>Priority</span>
          </nz-form-label>
          <nz-form-control [nzSm]="3" [nzXs]="24">
            <label nz-checkbox formControlName="isPriority" id="isPriority"></label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>

      <nz-col>
        <ng-container *ngIf="!this.editObject && form.get('status').value === statusTypes.Open">
          <button
            (click)="addVariant()"
            nz-button
            nzType="primary"
            type="button"
            id="add-product-row"
            [disabled]="!form.get('originStoreId').value || !form.get('targetStoreId').value"
          >Neuen Artikel hinzufügen</button>
          <br />
          <br />
        </ng-container>
        <nz-table nzBordered [nzData]="stockTransferArticleVariants" [nzShowPagination]="false">
          <thead>
            <tr>
              <th>Artikel</th>
              <th>Artikel Variante</th>
              <th>EAN</th>
              <th>Lagerbestand im Ursprung</th>
              <th>Menge für die Überweisung</th>
              <th>Lagerbestand im Ziel</th>
              <th *ngIf="form.get('status').value === statusTypes.Open"></th>
            </tr>
          </thead>
          <tbody>
            <tr (click)="startEdit(i)" *ngFor="let el of stockTransferArticleVariants; let i = index;" class="editable-row">
              <ng-container *ngIf="!el.isNew">
                <td>
                  {{ el.articleName }}
                </td>
                <td>{{ el.articleVariantName }}</td>
                <td>
                  {{ el.ean }}
                </td>
                <td>
                  {{form.controls.originStoreId.value ? el.originInStock : null }}
                </td>
                <td>
                  <ng-container *ngIf="editedRowId !== i">{{ el.quantity }}</ng-container>
                  <input
                    nz-input
                    type="number"
                    [value]="el.quantity"
                    *ngIf="editedRowId === i"
                    min="0"
                    #inputElement
                    (change)="quantityChanged(inputElement)"
                  >
                </td>
                <td>
                  {{ el.targetInStock }}
                </td>
              </ng-container>
              <ng-container *ngIf="el.isNew">
                <td>{{ el.articleName }}</td>
                <td>{{ el.articleVariantName }}</td>
                <td>
                  <input
                    type="text"
                    nz-input
                    placeholder="EAN"
                    #eanInputRef
                    (ngModelChange)="eanChanged($event, i)"
                    [(ngModel)]="el.ean"
                    [ngModelOptions]="{ standalone: true }"
                  >
                </td>
                <td>
                  {{form.controls.originStoreId.value ? el.originInStock : null }}
                </td>
                <td>
                  <ng-container *ngIf="editedRowId !== i">{{ el.quantity }}</ng-container>
                  <input
                    nz-input
                    type="number"
                    [value]="el.quantity"
                    *ngIf="editedRowId === i"
                    min="0"
                    #inputElement
                    (change)="quantityChanged(inputElement)"
                  >
                </td>
                <td>
                  {{form.controls.targetStoreId.value ? el.targetInStock : null }}
                </td>
              </ng-container>
              <td *ngIf="form.get('status').value === statusTypes.Open">
                <i
                  style="cursor: pointer;"
                  nz-icon nzType="delete"
                  nz-popconfirm
                  nzPopconfirmTitle="Sind Sie sicher?"
                  (nzOnConfirm)="removeRow(i)"
                  (click)="$event.stopPropagation();"
                  nzTheme="twotone"
                  [nzTwotoneColor]="'#eb2f96'"></i>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-col>
    </form>
  </nz-row>
</nz-spin>


<ng-template #noSelected>
  Auswählen
  <i nz-icon nzType="down"></i>
</ng-template>
