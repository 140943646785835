// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { menuItems } from './menu-items';

export const environment = {
  production: false,
  isSW: true,
  appName: 'BAOBAB Manager',
  apiUrl: 'https://dev.api.manager.gdw.zone/api',
  defaultResultsPerPage: 10,
  defaultDateFormat: 'dd.MM.yyyy',
  defaultTimeFormat: 'HH:mm',
  wsUrl: 'https://dev.api.manager.gdw.zone',
  appUrl: 'https://dev.gdw.zone',
  appOriginUrl: 'https://dev.manager.gdw.zone',
  menuItems,
  version: 'dev-314',
  shopUrls: [
    'https://www.dev.gewuerze-der-welt.net/',
    'https://www.dev.gewuerze-der-welt.net',
    'https://dev.gewuerze-der-welt.net/',
    'https://dev.gewuerze-der-welt.net',
  ]
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
