import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Renderer2,
  HostListener,
  EventEmitter,
  AfterViewInit, OnDestroy, ViewContainerRef, ViewChild, Output, Input
} from '@angular/core';
import { NotificationService } from '../../../core/services';
import { IOpenOrder } from '../core/interfaces/IOpenOrder';
import { Subscription } from 'rxjs';
import { DeliveryDocumentsWebSocketService } from '../core/services/DeliveryDocumentsWebSocketService';
import { Guid } from 'guid-typescript';
import { IGenerateOrderOperation } from '../core/interfaces/IGenerateOrderOperation';
import { ISignalRResponse } from '../../../core/interfaces/ISignalRResponse';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { OpenOrdersService } from '../core/services/OpenOrdersService';
import { IGenerateBatchOperation } from '../core/interfaces/IGenerateBatchOperation';
import { IBatchDocumentUrl } from '../core/interfaces/IBatchDocumentUrl';
import Files from '../../../core/utils/files';
import { OpenOrdersServiceErrorResolverService } from '../core/services/error-resolvers/open-orders-service-error-resolver.service';
import { map } from 'rxjs/operators';
import { GenerateDeliverySlipsModalComponent } from '../generate-delivery-slips-modal/generate-delivery-slips-modal.component';
import { FilterOperator } from '../../../core/types';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { InvoiceTypes } from '../../../core/enums/InvoiceTypes';
import { IDictionary } from '../../../core/interfaces/IDictionary';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('progressBar') progressbarModalContent;

  @Output() reloadBatchDocumentsList = new EventEmitter<void>();
  @Output() loaded = new EventEmitter<number>();
  @Input() set closedOrders(val) {
    if (val === true) {
      this.orderStatus = 7;
    }
    this.onlyClosed = true;
  }
  public onlyClosed = false;
  public data: IOpenOrder[];
  public tableMaxWidth: string;
  public loading = false;
  public allDocumentsReady = false;
  public documentsAreProcessing = false;
  public checked = false;
  public indeterminate = false;
  public setOfCheckedId = new Set<number>();
  public pdfLink;
  public orderStatus = 0;
  public numberOfPositions: string;
  public showOnlyWithComments = false;
  private _lastActionId: string;
  public percentDone: number;
  public selectedDate: string = null;
  public selectedDeliveryMethod: string = null;
  public readonly dateFormat = environment.defaultDateFormat;
  public readonly storageRange: { start: string; end: string; } = {
    start: null,
    end: null
  };
  public readonly numberOfPositionsOptions = [
    {
      label: 'kleine (< 5)',
      value: 'lt:5'
    },
    {
      label: 'große (>= 5)',
      value: 'ge:5'
    },
    {
      label: 'ein Artikel (= 1)',
      value: 'eq:1'
    }
  ];
  public readonly deliveryMethods: string[];

  private _numberOfDoneOperations: number;
  private _numberOfAllOperations: number;
  private _progressModal: NzModalRef;
  private _subscriptions: Subscription[] = [];


  constructor(
    private _service: OpenOrdersService,
    private _cdr: ChangeDetectorRef,
    private _notify: NotificationService,
    private renderer: Renderer2,
    private _errorResolver: OpenOrdersServiceErrorResolverService,
    private _wsService: DeliveryDocumentsWebSocketService,
    private _modal: NzModalService,
    private _viewContainerRef: ViewContainerRef,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.deliveryMethods = (this._activatedRoute.snapshot.data['deliveryMethods'] as IDictionary).value.split('; ');
  }

  ngOnInit(): void {
    this.load();
    this.initWs();
  }

  onRefreshList(): void {
    this.load();
  }

  @HostListener('window:resize')
  onResize() {
    this.setTableMaxHeight();
  }

  ngAfterViewInit(): void {
    this.setTableMaxHeight();
  }

  private setTableMaxHeight(): void {
    setTimeout(() => this.tableMaxWidth = this.renderer.selectRootElement('nz-table', true).offsetWidth + 'px');
  }

  private load(): void {
    this.loading = true;

    let numberOfPositions: { value: number; operator: FilterOperator; } = null;

    if (this.numberOfPositions) {
      const [operator, value] = this.numberOfPositions.split(':') as [FilterOperator, number];
      numberOfPositions = {
        value,
        operator
      };
    }

    this._subscriptions.push(
      this._service.getOpenOrders(
        this.orderStatus,
        this.selectedDate,
        this.selectedDeliveryMethod,
        numberOfPositions,
        this.storageRange
      ).pipe(
        map(item => {
          if (!this.showOnlyWithComments) {
            return item;
          }
          return item.filter(order => (order.comment || order.customerComment || order.internalComment));
        })
      ).subscribe(res => {
        this.loading = false;
        this.data = res;
        this.loaded.emit(this.data.length);
        this.onAllChecked(true);
        this._cdr.detectChanges();
      }, this.parseError)
    );
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.data.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.data.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.data.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
    const checkedItems = this.getCheckedItems();
    const filteredItems = checkedItems.filter(value => (value.isDeliveryNoteGenerated && (!value.isInvoiceRequired || (value.isInvoiceRequired && value.isInvoiceGenerated))));
    this.allDocumentsReady = filteredItems.length > 0 && checkedItems.length > 0 && checkedItems.length === filteredItems.length;
  }

  public regenerateDocument(id: number): void {
    this.loading = true;
    this._service.refreshDeliveryNote(id.toString()).subscribe(this.onRefreshList.bind(this), error =>  {
      this.loading = false;
      this._notify.pushError('Fehlgeschlagen', this._errorResolver.getMessage(error.response.errorCode ?? ''));
    });
  }

  public openGenerateDeliverySlipsModal(modal: GenerateDeliverySlipsModalComponent) {
    modal.orders = this.getCheckedItems().filter(
      order => (
        (order.deliveryMethod?.toUpperCase() === 'UPS' || order.deliveryMethod?.toUpperCase() === 'DHL') && order.trackingCode === '') ||
      order.deliveryMethod === null );
    modal.showModal();
  }

  public storageRangeChanged(): void {
    if ((this.storageRange.start && this.storageRange.end) || (!this.storageRange.start && !this.storageRange.end)) {
      this.onRefreshList();
    }
  }

  generateBatchDocument(): void {
    this.documentsAreProcessing = true;
    this._lastActionId = Guid.create().toString();
    const ids = this.getCheckedItems().map((value) => (value.id));
    const batch: IGenerateBatchOperation = {
      operationId: this._lastActionId,
      shopProviderKey: this.getCheckedItems()[0].shopProviderKey,
      orderIds: ids
    };
    this._numberOfDoneOperations = 0;
    this._numberOfAllOperations = 1;
    this.showProgressModal();
    this._service.generateBatchDocument(batch).subscribe(value => {
    }, this.parseError);
  }

  generateRequiredDocuments(): void {
    this.documentsAreProcessing = true;
    this._lastActionId =  Guid.create().toString();
    const ordersToGenerate = this.getCheckedItems().map(value => ({
      operationId: this._lastActionId,
      orderId: value.id,
      shopProviderKey: value.shopProviderKey
    } as IGenerateOrderOperation));
    this._numberOfDoneOperations = 0;
    this._numberOfAllOperations = ordersToGenerate.length;
    this.showProgressModal();
    this._service.generateDocuments(ordersToGenerate).subscribe(value => {
    }, this.parseError);
  }

  private initWs(): void {
    this._wsService.start();
    this._wsService.onWaitForGeneratedDocuments((response: ISignalRResponse<IOpenOrder>) => {
      if (response.hasError) {
        this._notify.pushError('Fehlgeschlagen', this._errorResolver.getMessage(response.errorCode ?? ''));
        this.resetDocumentProcess();
        return;
      }
      if (response.operationId === this._lastActionId) {
        const curEl = this.data.find(value => value.id === response.response.id);
        if (curEl) {
          this._numberOfDoneOperations++;
          curEl.isInvoiceGenerated = response.response.isInvoiceGenerated;
          curEl.isDeliveryNoteGenerated = response.response.isDeliveryNoteGenerated;
          this.refreshCheckedStatus();
          this.reCalcProgressBar();
        }
        if (this._numberOfDoneOperations === this._numberOfAllOperations && this._progressModal) {
          this._notify.pushSuccess('Fertig!', 'Erforderliche Dokumente erzeugt');
          this.onRefreshList();
          this.resetDocumentProcess();
        }
        this._cdr.detectChanges();
      }
    });

    this._wsService.onWaitForBatchDocument((response: ISignalRResponse<IBatchDocumentUrl>) => {
      if (response.hasError) {
        this._notify.pushError('Fehlgeschlagen', this._errorResolver.getMessage(response.errorCode ?? ''));
        this.resetDocumentProcess();
        return;
      }
      if (response.response.partiallyGenerated) {
        this._notify.pushError('Fehlgeschlagen', this._errorResolver.getMessage('PartialDocument'));
      }
      if (response.operationId === this._lastActionId) {
          this._numberOfDoneOperations++;
          this.refreshCheckedStatus();
          this.reCalcProgressBar();
        if (this._numberOfDoneOperations === this._numberOfAllOperations && this._progressModal) {
          this._notify.pushSuccess('Fertig!', 'Batch-dokument erzeugt');
          this.resetDocumentProcess();
          this.openBatchFile(response.response.url);
          this.load();
          this.reloadBatchDocumentsList.emit();
        }
        this._cdr.detectChanges();
      }
    });
  }

  private parseError = data => {
    this.resetDocumentProcess();
    this._notify.pushError('Fehlgeschlagen', this._errorResolver.getMessage((data && data.InternalCode) ? data.InternalCode : ''));
  }


  private getCheckedItems(): IOpenOrder[] {
    return this.data.filter(({id}) => this.setOfCheckedId.has(id));
  }

  private showProgressModal() {
    this._progressModal = this._modal.create({
      nzTitle: 'Dokumente bestellen',
      nzContent: this.progressbarModalContent,
      nzViewContainerRef: this._viewContainerRef,
      nzFooter: [
        {
          label: 'Abbrechen',
          onClick: () => {
            this.resetDocumentProcess();
          }
        }
      ]
    });
  }

  private reCalcProgressBar() {
    this.percentDone = Math.round(this._numberOfDoneOperations / this._numberOfAllOperations * 100);
    this._cdr.detectChanges();
  }

  private resetDocumentProcess() {
    if (this._progressModal) {
      this._progressModal.destroy();
      this._progressModal = null;
    }
    this.percentDone = 0;
    this.documentsAreProcessing = false;
    this._numberOfAllOperations = 0;
    this._numberOfDoneOperations = 0;
    this._cdr.detectChanges();
  }

  private openBatchFile(pdfLink: string) {
    Files.openByUrl(pdfLink, link => {
      this.pdfLink = pdfLink;
    });
  }

  public ngOnDestroy(): void {
    this._wsService.stop();
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }
}
