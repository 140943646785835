<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
  (nzAfterOpen)="afterModalOpen()"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <div class="ean-field">
      EAN Code: <input [(ngModel)]="eanCode" (ngModelChange)="handleNumberScan()" appPossitiveNumbers #scanField nz-input>
      &nbsp;<button nz-button nzType="primary">
        <i nz-icon nzType="barcode" nzTheme="outline" (click)="handleBarcodeScanner()"></i>
      </button>
    </div>
    <div class="location-number-field">
      Lagerplatz: <input [(ngModel)]="location" (ngModelChange)="handleLocationChange()" nz-input>
    </div>

    <nz-spin [nzSpinning]="loading">
      <nz-divider></nz-divider>
      <nz-row >
        <nz-col nzFlex="24">
          <nz-table
            nzShowSizeChanger
            [nzData]="locations"
            [nzShowPagination]="false"
          >
            <thead>
            <tr>
              <th>Produktname</th>
              <th>EAN</th>
              <th>Bestellnummer</th>
              <th>Lager</th>
              <th>Lagerplatz</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let location of locations">
              <tr *ngFor="let store of location.stores">
                <td>{{ location.articleName }} {{ location.variantName }}</td>
                <td>{{ location.ean }}</td>
                <td>{{ location.orderNumber }}</td>
                <td>{{ store.value }}</td>
                <td>{{ store.location }}</td>
              </tr>
            </ng-container>
            </tbody>
          </nz-table>
        </nz-col>
      </nz-row>
    </nz-spin>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
  </ng-template>
</nz-modal>
