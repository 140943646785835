import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IFormulaIngredient } from '../../own-production/core/interfaces/IFormulaIngredient';
import { OwnProductionService } from '../../own-production/core/OwnProductionService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArticlesService } from '../core/ArticlesService';
import { NotificationService } from '../../../core/services';
import { ArticlesServiceErrorResolverService } from '../core/error-resolvers/articles-service-error-resolver.service';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';

@Component({
  selector: 'app-add-edit-formula-form',
  templateUrl: './add-edit-formula-form.component.html',
  styleUrls: ['./add-edit-formula-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditFormulaFormComponent implements OnInit {
  articles$: Observable<IFormulaIngredient[]>;
  selectedArticles: IFormulaIngredient[] = [];
  private _articleId: number;
  @Input() set articleId(articleId: number) {
    this._articleId = articleId;

  }
  get articleId(): number {
    return this._articleId;
  }

  @Output() formFinished = new EventEmitter();
  @Output() newFormula = new EventEmitter();

  public loading = false;
  isSubmitted = false;
  isEdit = false;
  form: FormGroup;
  currentSearch: string;

  constructor(private _ownProductionService: OwnProductionService,
              protected _fb: FormBuilder,
              protected _service: ArticlesService,
              protected _notifyService: NotificationService,
              protected _errorResolver: ArticlesServiceErrorResolverService,
              private _cdr: ChangeDetectorRef
              ) {
    this.form = this._fb.group({
      name: ['', Validators.required],
      shortDescription: [''],
      isBio: [false],
      isOwnProduction: [false],
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.articles$ = this._service.getSimpleList('', true).pipe(map(this.castSimpleListToIngredient));
    this._ownProductionService.getFormulaIngredientsByArticleId(this.articleId).subscribe(value => {
      this.selectedArticles = value;
      this.loadForm();
    });
  }


  loadForm(): void {
    if (this.articleId) {
      this.loading = false;
      this._cdr.detectChanges();
    }
  }

  castSimpleListToIngredient(value: ISimpleListItem[]): IFormulaIngredient[]  {
    return value.map(ing => ({
      id: -1,
      articleId: -1,
      ingredientArticleId: parseInt(ing.id, 10),
      ingredientArticle: {
        value: `${ing.value}`,
        id: -1,
      }
    }));
  }

  removeFromList(obj: IFormulaIngredient) {
    this.isEdit = true;
    this.selectedArticles = this.selectedArticles.filter(value => value !== obj);
    this._cdr.detectChanges();
  }

  addToList(obj: IFormulaIngredient) {
    this.isEdit = true;
    obj.id = 0;
    obj.ingredientArticle.id = obj.ingredientArticleId;
    obj.articleId = this.articleId;
    this.selectedArticles.push({...obj});
    this._cdr.detectChanges();
  }

  search(phrase) {
    this.currentSearch = phrase;
    this.articles$ = this._service.getSimpleList(phrase, false).pipe(map(this.castSimpleListToIngredient));
  }

  submitForm() {
    if (this.isEdit) {
      this.loading = true;
      const articleFormulaIngredients = {
        'articleFormulaIngredients': this.selectedArticles
      };
      this._ownProductionService.updateFormulaIngredientsByArticleId(this.articleId, articleFormulaIngredients).subscribe(() => {
        this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt aktualisiert.`);
        this.formFinished.emit(true);
        this.newFormula.emit(this.selectedArticles);
      }, err => {
        this.parseError(err);
        this.formFinished.emit(false);
      });
    } else {
      this.newFormula.emit(this.selectedArticles);
      this.formFinished.emit(true);
    }

  }

  parseError = data => {
    if (data && data.InternalCode) {
      this._notifyService.pushError('Fehlgeschlagen', this._errorResolver.getMessage(data.InternalCode));
    } else {
      this._notifyService.pushError('Fehlgeschlagen', this._errorResolver.getMessage(''));
    }
  }
}
