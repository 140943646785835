import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authGuard } from '../../core/guards';
import { ProductionOrdersComponent } from './production-orders/production-orders.component';

const routes: Routes = [
  {
    path: '',
    component: ProductionOrdersComponent,
    canActivate: [ authGuard ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductionOrdersRoutingModule { }
