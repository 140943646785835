import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { IdentityService } from '../services';
import { take, tap } from 'rxjs/operators';

export const authGuard: CanActivateFn = (route, state): Observable<boolean> => {
  const router = inject(Router);
  const identityService = inject(IdentityService);

  return identityService.isAuthenticated.pipe(
    take(1),
    tap(auth => {
      if (!auth) {
        router.navigateByUrl('/auth/login');
      }
    })
  );
};
