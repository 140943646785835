<nz-spin [nzSpinning]="loading">
  <form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
    <nz-row>
      <nz-col [nzFlex]="12">
        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="role" nzRequired>Lieferant</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            <nz-select
              nzPlaceHolder="Wählen Sie Lieferant"
              nzShowSearch
              nzServerSearch
              formControlName="supplier"
              (nzOnSearch)="onSearchSupplier($event)"
              [compareWith]="compareSimpleList"
            >
              <ng-container *ngFor="let o of suppliersList">
                <nz-option *ngIf="!isSuppliersLoading" [nzValue]="o" [nzLabel]="o.value"></nz-option>
              </ng-container>
              <nz-option *ngIf="isSuppliersLoading" nzDisabled nzCustomContent>
                <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="deliveryDate" nzRequired>
            <span>Liefertermin</span>
          </nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <nz-date-picker
              [nzFormat]="dateFormat"
              formControlName="deliveryDate"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="charge" nzRequired>
            <span>Charge</span>
          </nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24" [nzErrorTip]="chargeError">
            <input
              nz-input
              id="charge"
              formControlName="charge"
              appChargeInput
              [initialValue]="editObject.charge"
              [articleId]="editObject.articleId"
              [articleVariantId]="editObject.articleVariantId"
            />
            <ng-template #chargeError let-control>
              @if (control.errors?.['required']) {
                Feld ist erforderlich!
              }
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="quantity" nzRequired>
            <span>Menge</span>
          </nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <div class="d-flex align-items-center">
              <input nz-input id="quantity" type="number" min="0" formControlName="quantity" [readOnly]="isVariant" />&nbsp;{{ isVariant ? 'Stück' : 'kg' }}
            </div>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="!isVariant">
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="status" nzRequired>Status</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            <nz-select
              nzPlaceHolder="Wählen Sie Status"
              formControlName="status"
            >
              <ng-container *ngFor="let o of bulkContainerStatuses | keyvalue">
                <nz-option [nzValue]="o.key" [nzLabel]="o.value"></nz-option>
              </ng-container>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="!isVariant && bulkContainerStatuses[form.value.status] === bulkContainerStatuses.Discarded">
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="comment">Entsorgungsgrund</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            <textarea nz-input formControlName="comment"></textarea>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col [nzFlex]="12">
        <nz-form-item style="height: 32px"></nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="mhd" nzRequired>
            <span>MHD</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <nz-date-picker
              [nzFormat]="dateFormat"
              formControlName="mhd"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="pricePerKg">
            <span>
              {{ !isVariant ? 'Preis netto pro Kilo' : 'Preis netto pro Stück' }}
            </span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <input nz-input id="pricePerKg" type="number" min="0" formControlName="pricePerKg" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="checked">
            <span>Wareneingang geprüft</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <label
              class="verify-checkbox"
              nz-checkbox formControlName="checked">Ja/Nein</label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
  </form>
</nz-spin>
