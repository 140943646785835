import { Observable } from 'rxjs';
import { ApiService } from './ApiService';
import { IService } from './IService';
import { ITableResults } from '../interfaces';
import { HttpUtils } from '../utils/HttpUtils';

export class GenericService<T> implements IService<T> {

  constructor(public apiService: ApiService) { }

  protected baseUrl = '';

  getQueryableAll(pageIndex: number,
                   pageSize: number,
                   sortField: string | null,
                   sortOrder: string | null,
                   filters: Array<{ key: string; value: string[] | string }>,
                   search: Array<{ key: string; value: string[] | string }>,
                  additionalFilters: Array<{ key: string; value: string[] | string }>
  ): Observable<ITableResults<T>> {
    const convertedFilters = filters.map(f => ({ ...f, value: Array.isArray(f.value) ? f.value : [f.value] }));
    const convertedSearch = search.map(s => ({ ...s, value: Array.isArray(s.value) ? s.value : [s.value] }));
    const convertedAdditionalFilters = additionalFilters.map(a => ({ ...a, value: Array.isArray(a.value) ? a.value : [a.value] }));
    
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, convertedFilters, convertedSearch, convertedAdditionalFilters);
    return this.apiService.get(`${this.baseUrl}`, params );
  }

  getById(id: string): Observable<T> {
    return this.apiService.get(`${this.baseUrl}/${id}`);
  }

  delete(id: string): Observable<T> {
    return this.apiService.delete(`${this.baseUrl}/${id}`);
  }

  update(object: T, id: number) {
    return this.apiService.put(`${this.baseUrl}/${id}`, object);
  }

  create(object: T) {
    return this.apiService.post(`${this.baseUrl}`, object);
  }
}
