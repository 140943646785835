<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  nzWidth="80%"
>
  <ng-template #modalTitle>Problem mit der Lieferung</ng-template>

  <ng-template #modalContent>
    <h3>Nachfolgend finden Sie einen Artikel mit einer Beschreibung der Fehler, die unmöglich in Flour eingeführt werden konnten</h3>
    <nz-table
      *ngIf="deliveryResponse"
      [nzData]="deliveryResponse.errorDetails">
      <thead>
      <tr>
        <th nzColumnKey="name" [nzSortFn]="true">Name</th>
        <th nzColumnKey="articleVariantName" [nzSortFn]="true">VariantName</th>
        <th nzColumnKey="ean" [nzSortFn]="true">EAN</th>
        <th nzColumnKey="number" [nzSortFn]="true">Number</th>
        <th nzColumnKey="error" [nzSortFn]="true">Error</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let prod of deliveryResponse.errorDetails">
        <td>{{prod.articleName }}</td>
        <td>{{prod.articleVariantName }}</td>
        <td>{{prod.ean }}</td>
        <td>{{prod.articleNumber }}</td>
        <td>{{ prod.error }}</td>
      </tr>
      </tbody>
    </nz-table>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="handleCancel()">OK</button>
  </ng-template>
</nz-modal>
