<app-inner-box>

  <nz-breadcrumb>
  <nz-breadcrumb-item>
    <a routerLink="/">
      <i nz-icon nzType="home"></i>
    </a>
  </nz-breadcrumb-item>
  <nz-breadcrumb-item>
    <a [routerLink]="parentLink">{{ parentTitle }}</a>
  </nz-breadcrumb-item>
  <nz-breadcrumb-item>
    {{ articleName }}
  </nz-breadcrumb-item>
</nz-breadcrumb>

  <div class="header d-flex align-items-center">
    <h1 style="margin-bottom: 0; margin-right: 12px;">{{title}}</h1>
    <div class="actions">
      <app-add-edit-article-modal #modal (refresh)="loadContent()">
        <span trigger>
          <i
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="modal.showModal($any({ id: this.articleId$.getValue(), name: articleName }))"
          ></i>
        </span>
      </app-add-edit-article-modal>
    </div>
  </div>

  <nz-divider></nz-divider>

  <ng-container *ngIf="bulkContainer$|async as bulkContainer; else loading">
    <nz-row [nzGutter]="16" nzJustify="center">
      <nz-col [nzSpan]="8">
        <nz-row [nzGutter]="50">
          <nz-col>
            <nz-statistic
              [nzValue]="bulkContainer.bulkContainerState | productionUnit:true"
              [nzTitle]="'Aktueller Stand'"
            ></nz-statistic>
          </nz-col>
          <nz-col>
            <div class="ant-statistic">
              <div class="ant-statistic-title">Baobab aus Eigenproduktion: </div>
              <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">
                  {{ bulkContainer.article.isOwnProduction | booleanFormat }}
                </span>
              </div>
            </div>
          </nz-col>
          <nz-col>
            <div class="ant-statistic">
              <div class="ant-statistic-title">Bio </div>
              <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">{{bulkContainer.article.isBio|booleanFormat}}</span>
              </div>
            </div>
          </nz-col>
        </nz-row>
      </nz-col>
      <nz-col [nzSpan]="8">
        <ng-container *ngIf="bulkContainer.orderQuantitySum === null">
          <h2>Keine weitere Lieferung geplant</h2>
        </ng-container>
        <ng-container *ngIf="bulkContainer.orderQuantitySum !== null">
          <h2>Nächste Lieferung</h2>
          <nz-divider></nz-divider>
          <nz-row [nzGutter]="50">
            <nz-col>
              <div class="ant-statistic">
                <div class="ant-statistic-title">Bestelltermin</div>
                <div class="ant-statistic-content">
                  <span class="ant-statistic-content-value">{{bulkContainer.orderDate|dateFormat}}</span>
                </div>
              </div>
            </nz-col>
            <nz-col><nz-statistic [nzValue]="(bulkContainer.orderSupplier?.name ?? '')" [nzTitle]="'Lieferant'"></nz-statistic></nz-col>
            <nz-col><nz-statistic [nzValue]="bulkContainer.orderQuantitySum | productionUnit:true" [nzTitle]="'Menge'"></nz-statistic></nz-col>
          </nz-row>
        </ng-container>
      </nz-col>
      <nz-col [nzSpan]="8">
        <h2>Kommentar</h2>
        <nz-divider></nz-divider>
        <nz-row [nzGutter]="50">
          <nz-col [nzSpan]="24">
            <div class="ant-statistic">
              <div class="ant-statistic-title">letzter Kommentar</div>
              <div class="ant-statistic-content">
                <div class="comment">
                  {{ bulkContainer.articleComment?.comment ?? '-' }}
                </div>
                <br>
                <button nz-button nzType="primary" (click)="showCommentControl = !showCommentControl">
                  Neuer Kommentar
                </button>
                <ng-container *ngIf="showCommentControl">
                  <textarea nz-input style="margin: 8px 0; height: 150px;" [(ngModel)]="newComment"></textarea>
                  <div class="d-flex justify-content-end">
                    <button nz-button nzType="default" (click)="saveComment()">
                      Neuer Kommentar speichern
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </nz-col>
        </nz-row>
      </nz-col>
    </nz-row>

    <nz-row>
      <nz-col [nzSpan]="6">
        <div class="ant-statistic">
          <div class="ant-statistic-title">
            Mindestlagerbestand festgelegt
          </div>
          <div class="ant-statistic-content">
            <div class="d-flex align-items-center">
              <input type="number" nz-input [(ngModel)]="bulkContainer.minStock">&nbsp;kg
              <button
                nz-button
                nzType="primary"
                nzShape="circle"
                style="margin-left: 16px;"
                (click)="updateMinStock(bulkContainer.minStock)"
              >
                <span nz-icon nzType="check"></span>
              </button>
            </div>
          </div>
        </div>
      </nz-col>
    </nz-row>
  </ng-container>

  <div class="card-container">
    <nz-tabset nzType="card" nzLinkRouter>
      <nz-tab (nzSelect)="true">
        <a *nzTabLink nz-tab-link
           [routerLink]="['.']"
           [queryParams]="{ tab: 'deliveries' }"
           queryParamsHandling="merge">
          Lieferungen
        </a>
        <app-deliveries-list
          [articleId]="articleId$ | async"
          (deliveryCreated)="loadContent()"
          (deliveryEdited)="loadContent()"
          (deliveryCancelled)="loadContent()"
          (newProductionOrderCreated)="listForArticleComponent.onRefreshList(true)"
          (changeLogCreated)="changeLogListComponent.onRefreshList(true); loadContent();"
        ></app-deliveries-list>
      </nz-tab>
      <nz-tab  >
        <a *nzTabLink nz-tab-link
           [routerLink]="['.']"
           [queryParams]="{ tab: 'production-orders' }"
           queryParamsHandling="merge">
          Produktionsaufträge
        </a>
        <app-list-for-article [articleId]="articleId$|async" #listForArticleComponent></app-list-for-article>
      </nz-tab>
      <nz-tab >
        <a *nzTabLink nz-tab-link
           [routerLink]="['.']"
           [queryParams]="{ tab: 'in-own-production' }"
           queryParamsHandling="merge">
          Warenausgang eigenproduktion
        </a>
        <app-own-productions-list [articleId]="articleId$|async"></app-own-productions-list>
      </nz-tab>
      <nz-tab >
        <a *nzTabLink nz-tab-link
           [routerLink]="['.']"
           [queryParams]="{ tab: 'change-log' }"
           queryParamsHandling="merge">
          Inverturkorrektur
        </a>
        <app-change-log-list [articleId]="articleId$|async" #changeLogListComponent></app-change-log-list>
      </nz-tab>
      <nz-tab >
        <a *nzTabLink nz-tab-link
           [routerLink]="['.']"
           [queryParams]="{ tab: 'stock-stores' }"
           queryParamsHandling="merge">
          Lagerhaus übersicht
        </a>
        <app-article-variants-stores-stocks
          style="width: 100%"
          [articleId]="articleId$|async"
        ></app-article-variants-stores-stocks>
      </nz-tab>

      <nz-tab>
        <a *nzTabLink nz-tab-link
           [routerLink]="['.']"
           [queryParams]="{ tab: 'comments' }"
           queryParamsHandling="merge">
          Kommentare Historie
        </a>
        <app-comments-list
          #commentsListComponent
          style="width: 100%"
          [articleId]="articleId$|async"
          (refreshArticle)="loadContent()"
        ></app-comments-list>
      </nz-tab>
    </nz-tabset>
  </div>

  <ng-template #loading>
    <nz-row [nzGutter]="16" nzJustify="center">
      <nz-col [nzXs]="24" [nzSm]="2">
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
      </nz-col>
    </nz-row>
  </ng-template>
</app-inner-box>
