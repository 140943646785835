<nz-spin [nzSpinning]="loading">
  <nz-row>
    <nz-col [nzFlex]="2" class="avatar-container">
      <nz-avatar [nzSize]="160" nzIcon="user" *ngIf="!isAvatarValue()"></nz-avatar>
      <app-avatar
        [sizePx]="160"
        *ngIf="isAvatarValue()"
        [fullName]="{ firstName: form.get('firstName').value, lastName: form.get('lastName').value }"
      ></app-avatar>
    </nz-col>
    <nz-col [nzFlex]="8">
      <form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="login" nzRequired nzTooltipTitle="What do you want other to login you">
            <span>Username</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte geben Sie Ihren Benutzernamen ein!">
            <input nz-input id="login" formControlName="login" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="firstName" nzRequired>
            <span>Name</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte geben Sie Ihren Vorname ein!">
            <input nz-input id="firstName" formControlName="firstName" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="lastName" nzRequired>
            <span>Nachname</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte geben Sie Ihren Benutzernamen ein!">
            <input nz-input id="lastName" formControlName="lastName" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">E-mail</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid E-mail!">
            <input nz-input formControlName="email" id="email" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="phone">Tel.</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <input nz-input formControlName="phone" id="phone" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="role" nzRequired>Rolle</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte wählen Sie eine der Rollen!">
            <nz-select formControlName="role">
              <nz-option [nzValue]="role" [nzLabel]="role" *ngFor="let role of roles"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="position" nzRequired>
            <span>Berufsbezeichnung</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte geben Sie Ihren Berufsbezeichnung ein!">
            <input nz-input id="position" formControlName="position" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="isProduction">
            <span>Is production</span>
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="12">
            <label
              nz-checkbox
              formControlName="isProduction"
            >Ja/Nein</label>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-col>
  </nz-row>
</nz-spin>

<ng-template #confirmModalContent>
  <p>Password is generated already: <strong>{{currentPassword}} </strong><br><br>
  You can send short instruction via email <a [href]="currentMailTo"><strong>here</strong></a>.
</ng-template>

<ng-template #confirmResetModalContent>
  <p>New password is generated already: <strong>{{currentPassword}} </strong><br><br>
  You can send short instruction via email <a [href]="currentMailTo"><strong>here</strong></a>.
</ng-template>
