import { Pipe, PipeTransform } from '@angular/core';
import { ISupplier } from '../../pages/suppliers/core/interfaces/ISupplier';

@Pipe({
  name: 'supplierTooltip'
})
export class SupplierTooltipPipe implements PipeTransform {
  transform(supplier: ISupplier): string {
    if (!supplier) return '';

    const details = [];
    if (supplier.contactPerson) details.push(`Ansprechpartner: ${supplier.contactPerson}`);
    if (supplier.phoneNumber) details.push(`Telefon: ${supplier.phoneNumber}`);
    if (supplier.email) details.push(`E-Mail: ${supplier.email}`);
    if (supplier.comment) details.push(`Kommentar: ${supplier.comment}`);
    return details.join('\n');
  }
}
