<nz-spin [nzSpinning]="loading">
  <ng-container *ngIf="packingListItem">
    <nz-divider></nz-divider>
    <nz-row [nzGutter]="16" nzJustify="start">
      <nz-col [nzSpan]="24">
        <nz-row [nzGutter]="50">
          <nz-col>
            <div class="ant-statistic">
              <div class="ant-statistic-title">Liste ID:</div>
              <div class="ant-statistic-content">
              <span class="ant-statistic-content-value">
                {{ packingListItem.id }}
              </span>
              </div>
            </div>
          </nz-col>
          <nz-col>
            <div class="ant-statistic">
              <div class="ant-statistic-title">Liste</div>
              <div class="ant-statistic-content">
              <span class="ant-statistic-content-value">
                {{ flourStockTypesTranslations[packingListItem.stock] }}
              </span>
              </div>
            </div>
          </nz-col>
          <nz-col>
            <div class="ant-statistic">
              <div class="ant-statistic-title">Erstellungsdatum:</div>
              <div class="ant-statistic-content">
              <span class="ant-statistic-content-value">
                {{ packingListItem.createdAt | datetimeFormat }}
              </span>
              </div>
            </div>
          </nz-col>
          <nz-col *ngIf="this.packingListItem.isMainDoc">
            <div class="ant-statistic">
              <div class="ant-statistic-title">Gescannte Artikel:</div>
              <div class="ant-statistic-content">
              <span class="ant-statistic-content-value">
                <ul class="scanned-articles">
                  <li *ngFor="let scannedArticle of scannedArticles">
                    {{scannedArticle.articleNumber}} | {{scannedArticle.articleName}} {{scannedArticle.articleVariantName}} - {{scannedArticle.delivery}}
                  </li>
                </ul>
              </span>
              </div>
            </div>
          </nz-col>
        </nz-row>
      </nz-col>
    </nz-row>
    <nz-divider></nz-divider>
    <ng-container *ngIf="currentVisibleArticleIndex !== null && this.packingListItem.isMainDoc || currentVisibleArticleIndex !== null && isOutgoingDocChanging">
      <nz-row [nzGutter]="16" nzJustify="start">
        <nz-col [nzSpan]="24">
          <nz-row style="margin-bottom: 24px;" [nzGutter]="100">
            <nz-col>
              <div class="ant-statistic">
                <div class="ant-statistic-title">SLN:</div>
                <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">
                  {{ currentScannedArticle.storeLocation }}
                </span>
                </div>
              </div>
            </nz-col>
            <nz-col>
              <div class="ant-statistic">
                <div class="ant-statistic-title">Artikel-Nr.</div>
                <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">
                  {{ currentScannedArticle.articleNumber }}
                </span>
                </div>
              </div>
            </nz-col>
            <nz-col>
              <div class="ant-statistic">
                <div class="ant-statistic-title">Name:</div>
                <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">
                  {{ currentScannedArticle.articleName }}
                </span>
                </div>
              </div>
            </nz-col>
            <nz-col>
              <div class="ant-statistic">
                <div class="ant-statistic-title">Variante:</div>
                <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">
                  {{ currentScannedArticle.articleVariantName }}
                </span>
                </div>
              </div>
            </nz-col>
          </nz-row>
          <nz-row [nzGutter]="100" style="margin-bottom: 24px;">
            <nz-col>
              <div class="ant-statistic">
                <div class="ant-statistic-title">Bestand Hechendorf:</div>
                <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">
                  {{ currentScannedArticle.mainStockInStore }}
                </span>
                </div>
              </div>
            </nz-col>
            <nz-col>
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Bestand {{ flourStockTypes[packingListItem.stock] === flourStockTypes.Munich ? 'Ruffini' : flourStockTypesTranslations[packingListItem.stock] }}
                </div>
                <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">
                  {{ currentScannedArticle.inStock }}
                </span>
                </div>
              </div>
            </nz-col>
          </nz-row>
          <nz-row nzJustify="center">
            <nz-input-number
              class="article-quantity-picker"
              [(ngModel)]="articleQuantity"
              [nzMin]="1"
              [nzMax]="100000"
            ></nz-input-number>
          </nz-row>
          <nz-row
            nzJustify="center"
            style="margin-top: 24px;"
            *ngIf="packingListItem.isMainDoc && currentScannedArticle.delivery > currentScannedArticle.mainStockInStore"
          >
            <nz-alert
              nzShowIcon
              nzType="error"
              nzMessage="Fehler"
              nzDescription="Artikelmenge ist kleiner als Menge in Hechendorf, bitte korrigieren und neue Menge speichern."
            ></nz-alert>
          </nz-row>
          <nz-row
            nzJustify="center"
            style="margin-top: 24px;"
            *ngIf="packingListItem.isMainDoc && currentScannedArticle.delivery === null"
          >
            <nz-alert
              nzShowIcon
              nzType="error"
              nzMessage="Fehler"
              nzDescription="Artikelmenge ist nicht eingestellt, bitte korrigieren Sie den Wert und speichern Sie."
            ></nz-alert>
          </nz-row>
        </nz-col>
      </nz-row>
      <nz-divider></nz-divider>
      <nz-row nzJustify="space-between">
        <nz-col>
          <button
            nz-button
            nzType="default"
            *ngIf="scannedArticles.length > 1 && currentVisibleArticleIndex > 0 && !isOutgoingDocChanging"
            (click)="backToPrevious()"
          >
            Zurück
          </button>
        </nz-col>
        <nz-col>
          <nz-row>
            <button
              nz-button
              nzType="primary"
              nz-popconfirm
              nzPopconfirmTitle="Bist du sicher?"
              nzOkText="Ja"
              nzCancelText="Nein"
              (nzOnConfirm)="deleteArticle()"
              style="margin-right: 24px;"
              *ngIf="packingListItem.isMainDoc"
            >Artikel löschen</button>
            <button
              nz-button
              nzType="primary"
              style="margin-right: 24px;"
              *ngIf="isOutgoingDocChanging"
              (click)="isOutgoingDocChanging = false"
            >Abbrechen</button>
            <button
              nz-button
              nzType="primary"
              style="margin-right: 24px;"
              [disabled]="
              (articleQuantity !== null && articleQuantity === currentScannedArticle.delivery)"
              (click)="saveArticleQuantity()"
            >Neue Liefermenge speichern</button>
            <button
              nz-button
              nzType="primary"
              (click)="generateDeliveryNote()"
              *ngIf="!isOutgoingDocChanging"
            >Lieferschein generieren</button>
          </nz-row>
        </nz-col>
      </nz-row>
      <nz-divider></nz-divider>
    </ng-container>
    <ng-container *ngIf="this.packingListItem.isOutgoingDoc">
      <nz-row [nzGutter]="16" nzJustify="start">
        <nz-col [nzSpan]="24" class="table-wrapper">

          <nz-table [nzFrontPagination]="false" [nzData]="scannedArticles">
            <thead>
            <tr>
              <th>SLN</th>
              <th>Artikel-Nr</th>
              <th>Name</th>
              <th>Variante</th>
              <th>Liefermenge</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of scannedArticles">
              <td>{{data.storeLocation}}</td>
              <td>{{data.articleNumber}}</td>
              <td>{{data.articleName}}</td>
              <td>{{data.articleVariantName}}</td>
              <td>{{data.delivery}}</td>
            </tr>
            </tbody>
          </nz-table>

        </nz-col>
      </nz-row>
      <nz-divider></nz-divider>
      <nz-row nzJustify="end">
        <nz-col >
          <nz-row >
            <button
              nz-button
              nzType="primary"
              nz-popconfirm
              nzPopconfirmTitle="Bist du sicher?"
              nzOkText="Ja"
              nzCancelText="Nein"
              (nzOnConfirm)="generateDeliveryNote()"
            >Lieferung bestätigen</button>
          </nz-row>
        </nz-col>
      </nz-row>
      <nz-divider></nz-divider>
    </ng-container>
    <div class="ean-scanner--container">
      <span>Das Produkt scannen: </span>
      <textarea
        [(ngModel)]="currentEan"
        (ngModelChange)="eanScan($event)"
        [ngModelOptions]="{ standalone: true }"
        #eanField
        nz-input
        rows="1"
      ></textarea>
    </div>
  </ng-container>
</nz-spin>


<app-delivery-note-error-modal #deliveryNoteErrorModalComponent />
