<nz-spin [nzSpinning]="loading">
  <form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
  <nz-row>
    <nz-col [nzFlex]="12">
      <ng-container *ngIf="bulkContainerObj">
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">Artikel</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{bulkContainerObj.article.name}}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">Lieferant</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{bulkContainerObj.supplier.name}}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">Charge</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{bulkContainerObj.charge}}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">MHD</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{bulkContainerObj.mhd | dateFormat}}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">Aktueller Lieferzustand</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{bulkContainerObj.state.value | productionUnit:true}}
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <nz-divider></nz-divider>
      <nz-form-item>
        <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="isPriority">
          <span>Prioritat</span>
        </nz-form-label>
        <nz-form-control [nzSm]="13" [nzXs]="24" >
          <nz-radio-group formControlName="isPriority">
            <label nz-radio [nzValue]=true>Ja</label>
            <label nz-radio [nzValue]=false>Nein</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="processingDate" nzRequired>
          <span>Bearbeitungsdatum</span>
        </nz-form-label>
        <nz-form-control [nzSm]="13" [nzXs]="24" nzErrorTip="Geben Sie die Bearbeitungsdatum!">
          <nz-date-picker
            [nzFormat]="dateFormat"
            formControlName="processingDate"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="worker" nzRequired>
          <span>Bearbeiter</span>
        </nz-form-label>
        <nz-form-control [nzSm]="13" [nzXs]="24" nzErrorTip="Geben Sie die Bearbeiter!">
          <nz-select
            style="max-width: 250px;"
            nzPlaceHolder="Wählen Sie Bearbeiter"
            nzShowSearch
            nzServerSearch
            formControlName="workers"
            (nzOnSearch)="onSearchUsers($event)"
            [compareWith]="compareSimpleList"
            nzMode="multiple"
          >
            <ng-container *ngFor="let o of usersList">
              <nz-option *ngIf="!isUsersLoading" [nzValue]="o" [nzLabel]="o.value"></nz-option>
            </ng-container>
            <nz-option *ngIf="isUsersLoading" nzDisabled nzCustomContent>
              <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

    </nz-col>
    <nz-col [nzFlex]="12">

      <ng-container *ngIf="bulkContainerObj">
        <nz-form-item
          *ngFor="let val of (isEdit ? editObject.productionOrderVariants : bulkContainerObj.article.articleVariants); let i = index;"
          formArrayName="productionOrderVariants"
        >
          <ng-container [formGroupName]="i">
            <nz-form-label [nzSm]="14" [nzXs]="24" nzFor="price">
              <span *ngIf="!isEdit">{{val.name}} ({{ ((val.stocks | mainStoreNameByStocks)?.inStock) ?? 0 }})</span>
              <span *ngIf="isEdit">{{val.articleVariant.name}} ({{ ((val.articleVariant.stocks | mainStoreNameByStocks)?.inStock) ?? 0 }})</span>
            </nz-form-label>
            <nz-form-control [nzSm]="10" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
              <div>
                <input type="hidden" formControlName="articleVariantId">
                <input type="hidden" formControlName="weight" >
                <input type="hidden" formControlName="customWeight" >
                <nz-input-number
                  id="quantity"
                  formControlName="quantity"
                  nzMin="0"
                  [class.disabled]="!isEdit ? (!val.customWeight ? (val.purchaseUnit * 1000) : val.customWeight) === 0 : (!val.articleVariant.customWeight ? (val.articleVariant.purchaseUnit * 1000) : val.articleVariant.customWeight) === 0"
                ></nz-input-number>

                <ng-container *ngIf="!isEdit">
                  <span>
                    ({{ !val.customWeight ? (val.purchaseUnit * 1000) : val.customWeight }} g)
                  </span>
                </ng-container>

                <ng-container *ngIf="isEdit">
                  <span>
                    ({{ !val.articleVariant.customWeight ? (val.articleVariant.purchaseUnit * 1000) : val.articleVariant.customWeight }} g)
                  </span>
                </ng-container>

                <span
                  *ngIf="!isEdit ? (!val.customWeight ? (val.purchaseUnit * 1000) : val.customWeight) === 0 : (!val.articleVariant.customWeight ? (val.articleVariant.purchaseUnit * 1000) : val.articleVariant.customWeight) === 0"
                  nz-tooltip
                  nzTooltipTitle="Variante hat kein Gewicht!"
                  nz-icon nzType="question-circle"
                  nzTheme="twotone"
                  style="margin-left: 8px;"
                ></span>
              </div>
              <div
                *ngIf="((!isEdit ? val.name : val.articleVariant.name) | lowercase | stringIncludes:'mit rezeptkarte') || ((!isEdit ? val.name : val.articleVariant.name) | lowercase | stringIncludes:'goodie')"
                style="margin-top: 10px; max-width: 256px;"
              >
                <nz-select
                  nzPlaceHolder="Wählen Sie Rezeptkarte"
                  nzShowSearch
                  nzServerSearch
                  formControlName="recipeCardVariantId"
                  nzAllowClear
                  (nzOnSearch)="onRecipeCardsSearch($event)"
                >
                  <ng-container *ngFor="let o of recipeCardsList">
                    <nz-option *ngIf="!isRecipeCardsLoading" [nzValue]="o.id" [nzLabel]="o.value"></nz-option>
                  </ng-container>
                  <nz-option *ngIf="isRecipeCardsLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
                  </nz-option>
                </nz-select>
              </div>
            </nz-form-control>
          </ng-container>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24" >
            <span>Restmenge</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" >
            {{ (this.restValue$|async|productionUnit:true) }}
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <nz-form-item>
        <nz-form-label [nzSm]="10" [nzXs]="24">Bemerkung</nz-form-label>
        <nz-form-control [nzSm]="13" [nzXs]="24">
          <textarea rows="4" formControlName="comment" nz-input></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

</form>
</nz-spin>
