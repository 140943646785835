import { IUnit } from './IUnit';
import { IStock } from './IStock';
import { IArticleVariantPrice } from './IArticleVariantPrice';
import { IStore } from 'src/app/pages/stores/core/interfaces/IStore';


export interface IArticleVariant {
  articleId?: number;
  articleName?: string;
  articleVariantConfigurationId?: number;
  ean?: string;
  id?: number;
  isDefault?: boolean;
  labelKey?: string;
  name?: string;
  orderNumber?: string;
  fullName?: string;
  packUnit?: string;
  purchaseUnit?: number;
  stockMin?: number;
  isActive?: boolean;
  isActiveForProduction?: boolean;
  unit?: IUnit;
  weight?: number;
  stocks?: IStock[];
  isEdited?: boolean;
  customWeight?: number;
  isNotRelevantForPacking?: boolean;
  expanded?: boolean;
  price?: IArticleVariantPrice;
  mhd?: string;
  storageInfos?: { storeId: number; storageLocationNumber: string; }[];
  storeLocations?: IStoreLocation[];
  storageLocationNumber?: string;
}

export interface IStoreLocation  {
  articleVariantId: number;
  store: IStore;
  storageLocation: string;
}
