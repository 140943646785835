import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, ViewChild } from '@angular/core';
import { ListComponentGeneric } from '../../../ui/generics';
import { NotificationService } from '../../../core/services';
import { IArticle } from '../../../core/interfaces';
import { ArticlesService } from '../core/ArticlesService';
import { ArticlesServiceErrorResolverService } from '../core/error-resolvers/articles-service-error-resolver.service';
import { ToolsService } from '../../../core/services/ToolsService';
import { AddEditArticleModalComponent } from '../../../shared/modals/add-edit-article-modal/add-edit-article-modal.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent extends ListComponentGeneric<IArticle> implements OnInit {

  @ViewChild('modal') modal: AddEditArticleModalComponent;

  constructor(
    protected _service: ArticlesService,
    protected _cdr: ChangeDetectorRef,
    protected _notify: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: ArticlesServiceErrorResolverService,
    private readonly _toolsService: ToolsService
  ) {
    super(_service, _cdr, _notify, renderer, _errorResolver);
  }

  searchColumns = [
    'name',
    'orderNumber',
    'id'
  ];

  protected readonly exportedTableVisibleName = 'Articles list';

  public clearCache(): void {
    this._toolsService.clearCache().subscribe(() => {
      const { pageIndex, pageSize, filter, sortField, sortOrder, search } = this.lastLoadParams;
      this.load(pageIndex, pageSize, sortField, sortOrder, filter, search);
    }, this.parseError.bind(this));
  }

  public openModal(article: IArticle, timeout = 0): void {
    setTimeout(() => {
      this.modal.showModal(article);
      this._cdr.detectChanges();
    }, timeout);
  }
}
