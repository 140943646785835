import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticlesComponent } from './articles/articles.component';
import { ArticlesRoutingModule } from './articles-routing.module';
import { UiModule } from '../../ui/ui.module';
import { ListComponent } from './list/list.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { AddEditVariantFormComponent } from './add-edit-variant-form/add-edit-variant-form.component';
import { AddEditVariantModalComponent } from './add-edit-variant-modal/add-edit-variant-modal.component';
import { CoreModule } from '../../core/core.module';
import { AddEditFormulaFormComponent } from './add-edit-formula-form/add-edit-formula-form.component';
import { StorestocksModule } from '../stores-stocks/storestocks.module';
import { SharedModule } from '../../shared/shared.module';
import { ChangeMhdModalComponent } from './change-mhd-modal/change-mhd-modal.component';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';

@NgModule({
  declarations: [
    ArticlesComponent,
    ListComponent,
    AddEditVariantFormComponent,
    AddEditVariantModalComponent,
    AddEditFormulaFormComponent,
    ChangeMhdModalComponent,
  ],
  imports: [
    CommonModule,
    ArticlesRoutingModule,
    CoreModule,
    UiModule,
    NzListModule,
    NzTabsModule,
    StorestocksModule,
    SharedModule,
    NzStatisticModule
  ],

  exports: [
    AddEditFormulaFormComponent,
    ChangeMhdModalComponent
  ]
})
export class ArticlesModule { }
