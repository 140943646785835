<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <div style="margin-right: auto; width: 500px">
    <div class="d-flex">
      <span class="action">
        <app-download-documents [documentKeys]="selectedDocumentKeys"></app-download-documents>
      </span>
      <div class="uncompleted-orders-checkbox-wrapper">
        <label
          nz-checkbox
          [(ngModel)]="showUncompletedOrders"
          (ngModelChange)="valueChanged()"
        >Nicht abgeschlossene Bestellungen</label>
      </div>
    </div>
    <div class="full-width" style="margin-top: 24px;">
      <label for="lastSupplier">Lieferant</label>
      <nz-select
        class="full-width"
        nzPlaceHolder="Wählen Sie Lieferant"
        nzShowSearch
        nzServerSearch
        (nzOnSearch)="onSearchSupplier($event)"
        [compareWith]="compareSimpleList"
        nzMode="multiple"
        [(ngModel)]="selectedLastSuppliers"
        (ngModelChange)="onRefreshList()"
        id="lastSupplier"
      >
        <ng-container *ngFor="let o of suppliersList">
          <nz-option *ngIf="!isSuppliersLoading" [nzValue]="o" [nzLabel]="o.value"></nz-option>
        </ng-container>
        <nz-option *ngIf="isSuppliersLoading" nzDisabled nzCustomContent>
          <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
        </nz-option>
      </nz-select>
    </div>
  </div>
</app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
    <tr>
    <th
      [(nzChecked)]="checked"
      [nzIndeterminate]="indeterminate"
      (nzCheckedChange)="onAllChecked($event)"
      nzWidth="60px"
    ></th>
    <th nzColumnKey="id" [nzSortFn]="true" nzWidth="75px" nzSortOrder="descend">#ID</th>
    <th nzColumnKey="orderDate" [nzSortFn]="true">Bestelltermin</th>
    <th
      nzColumnKey="supplier.name" [nzSortFn]="true">
      Lieferant
    </th>
    <th
      nzColumnKey="status"
      [nzFilters]="statusFilters"
      [nzFilterMultiple]="true"
      [nzFilterFn]="true"
      [nzSortFn]="true"
      (nzFilterChange)="filtersChanged()"
    >
      Status
    </th>
    <th nzRight nzWidth="120px"></th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of data.results">
    <td [nzChecked]="setOfCheckedId.has(order.id)" (nzCheckedChange)="onItemChecked(order.id, $event)"></td>
    <td>{{order.id}}</td>
    <td>{{order.orderDate | dateFormat}}</td>
    <td>{{order.supplier.name}}</td>
    <td>{{order.status | statusFormat}}</td>
    <td nzRight>
      <div class="actions" style="align-items: center;">
          <span>
            <i nz-icon nzType="edit"  nzTheme="outline" (click)="modal.showModal(order)"></i>
          </span>
        <span>
          <app-download-document
            *ngIf="order.documentKey && order.documentKey !== ''"
            [documentKey]="order.documentKey"
          ></app-download-document>
        </span>
        <span *ngIf="order.status === 'Open'">
            <i nz-icon nzType="stop" nz-popconfirm nzPopconfirmTitle="Sind Sie sicher?" (nzOnConfirm)="deleteElement(order.id)"  nzTheme="outline"></i>
        </span>
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-add-edit-order-modal (refresh)="onRefreshList(false)" #modal [orderType]="orderType"></app-add-edit-order-modal>
<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
