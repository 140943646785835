import { Pipe, PipeTransform} from '@angular/core';
import { IIdAndName } from '../interfaces';

@Pipe({
  name: 'taxNameById'
})
export class TaxNameByIdPipe implements PipeTransform {

  public transform(taxId: number, taxes: IIdAndName[]): string {
    return taxes.find(x => x.id === taxId)?.name;
  }
}
