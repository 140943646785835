import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, Input } from '@angular/core';
import { ListComponentGeneric } from '../../../../ui/generics';
import {
  OrdersService,
  OrdersServiceErrorResolverService
} from '../../core/services';
import { NotificationService } from '../../../../core/services';
import { IDeliveryOrder } from '../../core/interfaces/IDeliveryOrder';
import { SuppliersService } from '../../../suppliers/core/services/SuppliersService';
import { NzTableFilterList } from 'ng-zorro-antd/table';
import { debounceTime, finalize, map, switchMap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DeliveryOrderStatuses } from '../../../../core/enums';
import { OrderTypes } from '../../core/enums';
import { ISimpleListItem } from '../../../../core/interfaces/ISimpleListItem';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersListComponent extends ListComponentGeneric<IDeliveryOrder> implements OnInit {

  @Input() orderType = OrderTypes.MIXED;
  public orderTypes = OrderTypes;
  public isSuppliersLoading = false;
  public searchSupplierChange$ = new Subject();
  public selectedLastSuppliers: ISimpleListItem[] = [];
  public suppliersList: ISimpleListItem[];
  public showUncompletedOrders = false;
  public selectedDocumentKeys: string[] = [];
  statusFilters = Object.keys(DeliveryOrderStatuses).map(key => {
    return {
      value: key,
      text: DeliveryOrderStatuses[key],
      byDefault: false
    };
  });

  protected readonly exportedTableVisibleName = 'Bulk containers orders list';

  constructor(
    protected _service: OrdersService,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    private _supplierService: SuppliersService,
    protected renderer: Renderer2,
    protected _errorResolver: OrdersServiceErrorResolverService
  ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  ngOnInit(): void {
    const getSuppliers = (name: string) =>
      this._supplierService.getSimpleList(name);

    const suppliersOptionList$: Observable<ISimpleListItem[]> = this.searchSupplierChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getSuppliers));
    suppliersOptionList$.subscribe(data => {
      this.suppliersList = data;
      this.isSuppliersLoading = false;
      this._cdr.detectChanges();
    });
  }

  public filtersChanged(): void {
    this.showUncompletedOrders = false;
    this._cdr.detectChanges();
  }

  public valueChanged(): void {
    this.statusFilters = this.statusFilters.map(el => {
      el.byDefault = this.showUncompletedOrders && (el.value === 'Open' || el.value === 'PartlyFinished');
      return el;
    });
    this._cdr.detectChanges();
  }

  public onSearchSupplier(value: string): void {
    this.isSuppliersLoading = true;
    this.searchSupplierChange$.next(value);
  }


  load(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
    additionalFilters: { key: string; value: string[] }[] = [],
  ): void {
    filter = [
      ...filter,
      ...this.selectedLastSuppliers.map(x => ({
        key: 'supplier.name',
        value: [x.value]
      })),
    ];

    if (!sortField) {
      sortField = 'id';
    }

    if (!sortOrder) {
      sortOrder = 'descend';
    }

    this.lastLoadParams = {
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      search,
      additionalFilters
    };
    filter = [...filter, ...this.predefindedFilters];

    if (this.orderType !== OrderTypes.MIXED) {
      filter = [...filter, { key: 'orderType', value: [this.orderType] }];
    }

    if (this.keepStateDirective) {
      this.keepStateDirective.item = {
        ...this.lastLoadParams
      };
    }
    this.loading = true;
    this._listService.getQueryableAll(pageIndex, pageSize, sortField, sortOrder, filter, search, additionalFilters).pipe(
    ).subscribe(res => {
      this.loading = false;
      this.data = res;
      this._cdr.detectChanges();
    }, this.parseError);
  }

  deleteElement = (id: number) => {
    this.loading = true;
    this._service.cancel(id).pipe(finalize(() => this.loading = false)).subscribe(() => {
      this.onRefreshList(false);
    }, this.parseError);
  }

  public compareSimpleList(val1: ISimpleListItem, val2: ISimpleListItem): boolean {
    return val1?.id?.toString() === val2?.id?.toString();
  }

  protected refreshCheckedStatus(): void {
    super.refreshCheckedStatus();
    this.selectedDocumentKeys = this.data.results.filter(value => value.documentKey && this.setOfCheckedId.has(value.id)).map(value => (value.documentKey));
    this._cdr.detectChanges();
  }
}
