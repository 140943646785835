import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { IdentityService } from '../services';

export const noauthGuard: CanActivateFn = (route, state): Observable<boolean> => {
  const router = inject(Router);
  const identityService = inject(IdentityService);

  return identityService.isAuthenticated.pipe(
    take(1),
    map(isAuth => !isAuth),
    tap(noauth => {
      if (!noauth) {
        router.navigate(['/']);
      }
    })
  );
};
