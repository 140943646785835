export interface IArticleVariantPrice {
  price?: number;
  pseudoPrice?: number;
  comment?: string;
  container?: number;
  label?: number;
  marking?: number;
  productionTotal?: number;
  margin?: number;
  marginPercentage?: number;
  unitCost?: number;
  production?: number;
}
