import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import {
  ChargeInputDirective,
  GlobalModalDirective,
  InputTrimDirective,
  KeepStateDirective,
  ShowAuthedDirective,
  ShowForRoleDirective
} from './directives';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpApiVersionInterceptor, HttpRefreshTokenInterceptor, HttpTokenInterceptor } from './interceptors';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';
import {
  DateFormatPipe,
  BooleanFormatPipe,
  StatusFormatPipe,
  GetVariantsWithoutLabelPipe,
  SortMinutesAndHoursSelectOptionsPipe,
  DateTimeFormatPipe,
  ProductionUnitsFormatPipe,
  StockByStorePipe,
  MainStoreNameByStocksPipe, ProductionUnitFormatPipe, StringIncludesPipe, QueryParamsPipe, RouterLinkPipe,
} from './pipes';
import { PossitiveNumbersDirective } from './directives/possitive-numbers.directive';
import { DownloadFileUrlPipe } from './pipes/download-file-url.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { MapToArrayPipe } from './pipes/map-to-array.pipe';
import { TextInputOnlyNumbersDirective } from './directives/text-input-only-numbers.directive';
import { DragBackgroundPositionDirective } from './directives/drag-background-position.directive';
import { TaxNameByIdPipe } from './pipes/tax-name-by-id.pipe';



@NgModule({
  declarations: [
    ShowAuthedDirective,
    ShowForRoleDirective,
    GlobalModalDirective,
    DateFormatPipe,
    DateTimeFormatPipe,
    BooleanFormatPipe,
    StatusFormatPipe,
    KeepStateDirective,
    PossitiveNumbersDirective,
    DownloadFileUrlPipe,
    GetVariantsWithoutLabelPipe,
    TimeFormatPipe,
    MapToArrayPipe,
    TextInputOnlyNumbersDirective,
    DragBackgroundPositionDirective,
    SortMinutesAndHoursSelectOptionsPipe,
    InputTrimDirective,
    ProductionUnitsFormatPipe,
    StockByStorePipe,
    MainStoreNameByStocksPipe,
    ProductionUnitFormatPipe,
    StringIncludesPipe,
    ChargeInputDirective,
    QueryParamsPipe,
    RouterLinkPipe,
    TaxNameByIdPipe
  ],
  imports: [
    CommonModule,
    NzNotificationModule,
    NzModalModule
  ],
  exports: [
    ShowAuthedDirective,
    ShowForRoleDirective,
    GlobalModalDirective,
    DateTimeFormatPipe,
    DateFormatPipe,
    BooleanFormatPipe,
    StatusFormatPipe,
    KeepStateDirective,
    PossitiveNumbersDirective,
    DownloadFileUrlPipe,
    GetVariantsWithoutLabelPipe,
    TimeFormatPipe,
    MapToArrayPipe,
    TextInputOnlyNumbersDirective,
    DragBackgroundPositionDirective,
    DragBackgroundPositionDirective,
    SortMinutesAndHoursSelectOptionsPipe,
    InputTrimDirective,
    ProductionUnitsFormatPipe,
    StockByStorePipe,
    MainStoreNameByStocksPipe,
    ProductionUnitFormatPipe,
    StringIncludesPipe,
    ChargeInputDirective,
    RouterLinkPipe,
    QueryParamsPipe,
    TaxNameByIdPipe
  ],
  providers: [
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpApiVersionInterceptor, multi: true },
  ]
})
export class CoreModule { }
