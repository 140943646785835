<nz-spin [nzSpinning]="loading">
  <nz-tabset>
    <nz-tab nzTitle="Übersicht">
      <nz-row>
        <form nz-form [formGroup]="form" style="width: 100%" (ngSubmit)="submitForm()">
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="name" nzRequired>
              <span>Name</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte geben Sie den Namen des Lieferanten ein!">
              <nz-select
                nzShowSearch
                nzAllowClear
                [nzDropdownRender]="renderTemplate"
                nzPlaceHolder=""
                id="articleVariantConfigurationId"
                formControlName="articleVariantConfigurationId"
                (ngModelChange)="nameChanged()"
                (nzOnSearch)="nameSearch($event)"
              >
                <nz-option *ngFor="let item of articleVariantConfigurations" [nzLabel]="item.value" [nzValue]="item.id"></nz-option>
              </nz-select>
              <ng-template #renderTemplate>
                <nz-divider></nz-divider>
                <div class="container">
                  <input type="text" nz-input #inputElement />
                  <a class="add-item" (click)="addVariantName(inputElement.value)"><i nz-icon nzType="plus"></i> hinzufügen</a>
                </div>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="orderNumber" nzRequired>
              <span>Bestellnummer</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte Bestellnummer eingeben!">
              <input nz-input id="orderNumber" formControlName="orderNumber">
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="ean">
              <span>EAN</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte geben Sie EAN ein!">
              <input nz-input id="ean" formControlName="ean" type="number" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="stockMin">
              <span>Lager min</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input nz-input id="stockMin" formControlName="stockMin" type="number" />
            </nz-form-control>
          </nz-form-item>

          <nz-row nzJustify="center">
            <nz-col [nzSm]="6" [nzXs]="12">
              <nz-form-item>
                <nz-form-label nzFor="isGiftbox">
                  <span>Geschenkset</span>
                </nz-form-label>
                <nz-form-control>
                  <label
                    class="verify-checkbox"
                    nz-checkbox formControlName="isGiftbox" nzDisabled>Ja/Nein</label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col [nzSm]="6" [nzXs]="12">

            </nz-col>
          </nz-row>
          <nz-form-item *ngIf="form.controls['isGiftbox'].value" class="formula">
            <nz-form-label [nzXs]="24" style="text-align: left">
              <span>Formula Geschenkset</span>
            </nz-form-label>
            <nz-form-control [nzXs]="24" [nzSm]="12">
              <ul nz-list [nzDataSource]="selectedGiftboxArticles" nzBordered nzSize="large" class="form-control form-control--selected-articles">
                <nz-list-header>ausgewählte Artikel</nz-list-header>
                <li nz-list-item *ngFor="let item of selectedGiftboxArticles; let i = index;" nzNoFlex class="article-item">
                  <ul nz-list-item-actions>
                    <nz-list-item-action>
                      <i nz-icon nzType="close" nzTheme="outline" (click)="removeGiftboxFromList(i)"></i>
                    </nz-list-item-action>
                  </ul>
                  {{ item.fullName }}
                </li>
              </ul>
            </nz-form-control>
            <nz-form-control [nzXs]="24" [nzSm]="12">
              <ul nz-list [nzDataSource]="articles" nzBordered nzSize="large" *ngIf="giftboxFormulaArticles$ | async as articles">
                <nz-list-header>alle Artikel <app-table-search-bar (search)="searchGiftboxArticles($event)" [searchValue]="gitboxSearch"></app-table-search-bar></nz-list-header>
                <li nz-list-item *ngFor="let item of articles" nzNoFlex class="article-item">
                  <ul nz-list-item-actions>
                    <nz-list-item-action>
                      <i nz-icon nzType="arrow-left" nzTheme="outline" (click)="addGiftboxToList(item)"></i>
                    </nz-list-item-action>
                  </ul>
                  {{ item.fullName }}
                </li>
              </ul>
            </nz-form-control>
          </nz-form-item>
        </form>
      </nz-row>
    </nz-tab>
    <nz-tab nzTitle="Großgebinde / Produktion" *ngIf="false">
      <nz-row>
        <form nz-form [formGroup]="form" style="width: 100%">
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="weight">
              <span>Gewicht</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input nz-input id="weight" formControlName="weight" type="number" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="purchaseUnit">
              <span>Kaufwert</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input nz-input id="purchaseUnit" formControlName="purchaseUnit" type="number" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="packUnit">
              <span>Kaufeinheit</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input nz-input id="packUnit" formControlName="packUnit" type="text" />
            </nz-form-control>
          </nz-form-item>
        </form>
      </nz-row>
    </nz-tab>
  </nz-tabset>
</nz-spin>
