import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authGuard } from '../../core/guards';
import { BulkContainersComponent } from './bulk-containers/bulk-containers.component';
import { OrdersComponent } from './orders/orders.component';
import { SingleBulkContainerComponent } from './bulk-containers/single-bulk-container/single-bulk-container.component';
import { NonPackingComponent } from './non-packing/non-packing.component';
import { SingleNonPackingComponent } from './non-packing/single-non-packing/single-non-packing.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  },
  {
    path: 'overview',
    component: BulkContainersComponent,
    canActivate: [ authGuard ]
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [ authGuard ]
  },
  {
    path: 'non-packing',
    component: NonPackingComponent,
    canActivate: [ authGuard ]
  },
  {
    path: 'bulk-container/:id',
    component: SingleBulkContainerComponent,
    canActivate: [ authGuard ],
  },
  {
    path: 'non-packing/:id',
    component: SingleNonPackingComponent,
    canActivate: [ authGuard ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BulkContainersRoutingModule { }
