import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AddEditModalGeneric } from '../../../ui/generics';
import { IArticle } from '../../../core/interfaces';
import { AddEditArticleFormComponent } from '../../../shared/forms/add-edit-article-form/add-edit-article-form.component';

@Component({
  selector: 'app-add-edit-variant-modal',
  templateUrl: './add-edit-variant-modal.component.html',
  styleUrls: ['./add-edit-variant-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditVariantModalComponent extends AddEditModalGeneric<IArticle, AddEditArticleFormComponent> implements OnInit {

  @Input() isGiftbox = false;
  @Input() articleId: number;

  @Output() reloadVariantsList = new EventEmitter<void>();

  constructor(
    protected cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  rebuildTitle(): void {
    this.title = this.isNew ? 'Create new variant' : `Edit variant: ${this.object.name}`;
  }

  handleOk(): void {
    super.handleOk();
  }

  onFormFinished(state: boolean) {
    super.onFormFinished(state);
    if (state) {
      this.reloadVariantsList.emit();
    }
  }
}
