import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef, OnDestroy, ViewContainerRef, ViewChild, ElementRef, TemplateRef
} from '@angular/core';

import { IGlobalModal, D_GLOBAL_MODAL } from '../../../core/directives';
import { Subject } from 'rxjs';
import { debounceTime, distinct, map } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DocumentCodes } from '../../../core/enums';
import { OpenOrdersService } from '../core/services/OpenOrdersService';
import { NotificationService } from '../../../core/services';
import { OpenOrdersServiceErrorResolverService } from '../core/services/error-resolvers/open-orders-service-error-resolver.service';
import { DeliveryNote, DeliveryNoteArticle } from '../core/interfaces';
import Fields from '../../../core/utils/fields';
import Files from '../../../core/utils/files';
import { ModalGeneric } from '../../../ui/generics';
import { QrcodeScannerComponent } from '../../../ui/qrcode-scanner/qrcode-scanner.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-delivery-note-process-modal',
  templateUrl: './delivery-note-process-modal.component.html',
  styleUrls: ['./delivery-note-process-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: DeliveryNoteProcessModalComponent }]
})
export class DeliveryNoteProcessModalComponent extends ModalGeneric implements OnInit, OnDestroy, IGlobalModal {
  @ViewChild('scanField') scanField: ElementRef<HTMLInputElement>;
  @ViewChild('eanField') eanField: ElementRef<HTMLInputElement>;
  private _orderNumber = '';
  private _orderNumberChange$ = new Subject();
  private _eanChange$ = new Subject();
  public deliveryNote: DeliveryNote;
  public loading = false;
  public ean: string;
  public isVisible = false;
  public title = 'Bestellung versenden';
  public pdfLink: string;
  public uncompletedReason = '';
  public isProcessedCorrect = false;
  public isManualInput = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    private _modal: NzModalService,
    private _viewContainerRef: ViewContainerRef,
    private _service: OpenOrdersService,
    private _notifyService: NotificationService,
    private _errorResolver: OpenOrdersServiceErrorResolverService
  ) {
    super(cdr);
  }

  ngOnInit() {
    const numberChanged$ = this._orderNumberChange$
      .asObservable()
      .pipe(debounceTime(500));
    const eanChanged$ = this._eanChange$
      .asObservable()
      .pipe(debounceTime(500));

    numberChanged$.subscribe(this.loadDeliveryNote);
    eanChanged$.subscribe(this.eanScan);
  }

  set orderNumber(val: string) {
    if (val.slice(0, 2) === DocumentCodes.DELIVERY_NOTE) {
      this._orderNumber = val.slice(2);
      this._orderNumberChange$.next(this._orderNumber);
      return;
    }
    this._orderNumber = val;
  }

  get orderNumber() {
    return this._orderNumber;
  }

  afterModalOpen() {
    if (this.scanField) {
      this.setFocusOnScanField();
    }
  }

  notCompletedOrder(template: TemplateRef<any>) {

    this._modal.create({
      nzTitle: 'Grund angeben',
      nzContent: template,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => {
        this.setUnCompleted();
      }
    });
  }

  createBarcodeScannerModal(): void {
    const modal = this._modal.create({
      nzTitle: 'Barcode scanner',
      nzContent: QrcodeScannerComponent,
      nzViewContainerRef: this._viewContainerRef,
      nzWidth: 689,
      nzFooter: [
        {
          label: 'Ok',
          type: 'primary',
          onClick: () => modal.destroy()
        }
      ]
    });
    modal.afterClose.subscribe(result => {
      this.orderNumber = result?.code ?? '';
      this.cdr.detectChanges();
    });

    this.cdr.detectChanges();
  }

  setFocusOnScanField(): void {
    // this.orderNumber = '';
    setTimeout(() => this.scanField.nativeElement.focus(), 100);
  }

  eanScan = (val: string): void  => {
    val = val.trim();
    if (val !== '') {
      if (!this.deliveryNote.orderCompleted) {
        this.analyzeProducts(val);
      } else {
        if (this.deliveryNote.checkTrackingNumber(val)) {
          this.finishOrder();
        } else {
          this._notifyService.pushError(undefined, this._errorResolver.getMessage('TrackCodeNotCorrect'));
          Fields.SetAsWrong(this.eanField, this.clearEan);
        }
      }
    }
  }

  handleForceFinish() {
    if (!this.deliveryNote.trackingCode && this.deliveryNote.orderCompleted) {
      this.finishOrder();
    } else {
      this._notifyService.pushError(undefined, this._errorResolver.getMessage('CantFinish'));
      Fields.PlayWrongScanSound();
    }
  }

  private finishOrder() {
    this.loading = true;
    this.cdr.detectChanges();
    this._service.finishOpenOrder(this.deliveryNote.id.toString()).subscribe(() => {
      this.deliveryNote = null;
      this.loading = false;
      this.orderNumber = '';
      this.scanField.nativeElement.focus();
      this.clearEan();
      this.showCorrectBanner();
    }, (data) => {
      Fields.PlayWrongScanSound();

      if (data && data.InternalCode === 'ErrorOnFinishOpenOrder') {
        this.loading = false;
        this._notifyService.pushError('Fehlgeschlagen', `Bestellung bereits abgeschlossen: ${ data.Message }`);
      } else {
        this.parseError(data);
      }
    });
  }

  private setUnCompleted() {
    this.loading = true;
    this.cdr.detectChanges();
    this._service.setUncompletedOrder(this.deliveryNote.id.toString(), this.uncompletedReason).subscribe(() => {
      this.deliveryNote = null;
      this.loading = false;
      this.orderNumber = '';
      this.scanField.nativeElement.focus();
      this.clearEan();
      this.showCorrectBanner();
    }, (data) => {
      Fields.PlayWrongScanSound();
      this.parseError(data);
    });
  }

  private showCorrectBanner() {
    this.isProcessedCorrect = true;
    setTimeout(() => {
      this.isProcessedCorrect = false;
      this.cdr.detectChanges();
    }, 2000);
    this.cdr.detectChanges();
  }

  private analyzeProducts(value, attempt = false): boolean {
    const product = this.searchProduct(value);

    if (product) {
      if (!product.incrementNumberOfScan() ) {
        this._notifyService.pushWarning(undefined , this._errorResolver.getMessage('ProductTooMuch'));

        Fields.SetAsWrong(this.eanField, this.clearEan);
      } else {
        this._notifyService.pushSuccess(undefined , 'Das Produkt wurde hinzugefügt');
        this.ean = '';
      }
      this.checkCompletedProducts(product);
      this.cdr.detectChanges();
      return true;
    } else {
      if ((!attempt) && !this.analyzeProducts(value, true)) {
        Fields.SetAsWrong(this.eanField, this.clearEan);
      }
      return false;
    }
  }

  private checkCompletedProducts(product) {
    if (product.isFull()) {
      this.deliveryNote.checkIsOrderCompleted();
      if (this.deliveryNote.orderCompleted) {
        if (this.deliveryNote.deliveryMethod === 'CC') {

          this._modal.confirm({
            nzTitle: 'Info',
            nzContent: 'Bestellung vollständig: Click & Collect',
            nzOnOk: () => this.finishOrder()
          });
          return;
        }

        if (this.deliveryNote.trackingCode) {
          this.printTrackingSlip();
        } else {
          Fields.PlayWrongScanSound();
          this._notifyService.pushWarning(undefined , this._errorResolver.getMessage('TrackingCodeNotExists'));
        }
      }
    }
  }

  private printTrackingSlip() {
    Files.openPdfByBase64(this.deliveryNote.deliverySlip, link => {
      this.pdfLink = link;
      this.cdr.detectChanges();
    });
  }




  private loadDeliveryNote = () => {
    this.loading = true;
    this.cdr.detectChanges();
    this._service.getDeliveryNoteByOrderNumber(this._orderNumber).pipe(
      map(value => {
        return new DeliveryNote({
          ...value,
          articles: _(value.articles.filter(art => (art.orderNumber.toLowerCase() !== 'Spende'.toLocaleLowerCase())))
            .groupBy('orderNumber')
            .map((article, id) => (new DeliveryNoteArticle({
              ...article[0],
              quantity: _.sumBy(article, 'quantity'),
            }))).value()
        });
      })
    ).subscribe((val: DeliveryNote) => {
      this.deliveryNote = val;
      this.uncompletedReason = val.internalComment;
      this.loading = false;
      this.eanField.nativeElement.focus();
      this.cdr.detectChanges();
    }, (data) => {
      Fields.PlayWrongScanSound();
      if (!this.isManualInput) {
        this._orderNumber = '';
        this.cdr.detectChanges();
      }
      this.parseError(data);
    });
  }

  private searchProduct(val: string): DeliveryNoteArticle|null {
    return this.deliveryNote.articles.find(variant => variant.ean === val);
  }

  private searchProductById(id: number): DeliveryNoteArticle|null {
    return this.deliveryNote.articles.find(variant => variant.id === id);
  }

  handleCancel() {
    super.handleCancel();
    this.ean = '';
    this.orderNumber = '';
    this.deliveryNote = null;
  }

  handleNumberScan = (val): void => {
    if (val === '') {
      this.deliveryNote = null;
      return;
    }
    this._orderNumberChange$.next(val);
  }

  handleEANScan = (val): void => this._eanChange$.next(val);

  handleBarcodeScanner = (): void => this.createBarcodeScannerModal();

  markAsFull(id: number) {
    const product = this.searchProductById(id);
    product.makeFull();
    this.checkCompletedProducts(product);
    this.cdr.detectChanges();
  }

  clearEan = (): void => {
    this.ean = '';
    this.cdr.detectChanges();
  }

  private parseError = data => {
    this.loading = false;
    this._notifyService.pushError('Fehlgeschlagen', this._errorResolver.getMessage((data && data.InternalCode) ? data.InternalCode : ''));
  }




  ngOnDestroy(): void {

  }
}
