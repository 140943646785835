import { ChangeDetectorRef, Component, DestroyRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SwUpdate } from '@angular/service-worker';
import {
  ApiService,
  ExportService,
  ExportWebSocketService,
  GlobalModalsService,
  IdentityService, JwtService, LocalStorageService, NotificationService,
  TitleService
} from './core/services';
import { FlourService } from './pages/bulk-containers/core/services/FlourService';
import * as dayjs from 'dayjs';
import 'dayjs/locale/de';
import { IClaim } from './core/interfaces/auth/IClaim';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { SearchModalComponent } from './ui/modals/search-modal/search-modal.component';
import { DocumentCodes } from './core/enums';
import { StoragePlaceFinderModalComponent } from './pages/stores/storage-place-finder-modal/storage-place-finder-modal.component';
import { DeliveryNoteProcessModalComponent } from './pages/orders/delivery-note-process-modal/delivery-note-process-modal.component';
import { ProcessModalComponent } from './pages/production-orders/process-modal/process-modal.component';
import { AddEditOrderModalComponent } from './pages/bulk-containers/orders/add-edit-order-modal/add-edit-order-modal.component';
import { OrdersComponent } from './pages/bulk-containers/orders/orders.component';
import { bufferTime, filter, map } from 'rxjs/operators';
import { StockTransferComponent } from './pages/stock-transfer/stock-transfer/stock-transfer.component';
import { StockTransferProcessModalComponent } from './pages/stock-transfer/stock-transfer-process-modal/stock-transfer-process-modal.component';
import { environment } from '../environments/environment';
import { IFlourPackingListItem } from './pages/flour/core/interfaces';
import { ExportsPageComponent } from './pages/tools/pages/exports-page/exports-page.component';
import { ScannerService } from './core/services/ScannerService';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('searchModalComponent') searchModalComponent: SearchModalComponent;
  @ViewChild('storagePlaceFinderModalComponent') storagePlaceFinderModalComponent: StoragePlaceFinderModalComponent;
  @ViewChild('deliveryNoteProcessModalComponent') deliveryNoteProcessModalComponent: DeliveryNoteProcessModalComponent;
  @ViewChild('processModalComponent') processModalComponent: ProcessModalComponent;
  @ViewChild('addEditOrderModalComponent') addEditOrderModalComponent: AddEditOrderModalComponent;
  @ViewChild('stockTransferProcessModal') stockTransferProcessModal: StockTransferProcessModalComponent;

  public isCollapsed = false;
  public readonly menuItems = environment.menuItems;
  public currentUser: IClaim;
  public workstationMode = false;
  public activatedComponent: any;
  public showBannerAutologout = false;
  public version = '';
  private _ctrlOrCommandKeyClicked = false;
  private _enterKeyClicked = false;
  private readonly _md = 768;
  private _sub: Subscription;
  private _scannedVal = '';
  private _timer: ReturnType<typeof setTimeout>;
  private readonly _destroyRef = inject(DestroyRef);

  constructor(
    private _swUpdate: SwUpdate,
    private _identityService: IdentityService,
    private _jwtService: JwtService,
    private readonly _titleService: TitleService,
    private _globalModalService: GlobalModalsService,
    private _exportWebSocketService: ExportWebSocketService,
    private _exportService: ExportService,
    private _notificationService: NotificationService,
    private _router: Router,
    private _localStorafeSrvice: LocalStorageService,
    private _cdr: ChangeDetectorRef,
    private _flourService: FlourService<IFlourPackingListItem>,
    private _apiService: ApiService,
    private _scannerService: ScannerService,
  ) {
    this._sub = this._router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        // @ts-ignore
        const fMenuItem = this.menuItems.find(item => item.elements?.find(nestedItem => nestedItem.routerLink === evt.urlAfterRedirects));
        fMenuItem ? fMenuItem.isOpen = true : this.menuItems[0].isOpen = true;
        this._sub.unsubscribe();
      }
    });

    this._router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(val => {
      this.saveNavagationEnd(val['url']);
    });
  }

  @HostListener('window:resize')
  @HostListener('window:load')
  private onResize(): void {
    this.isCollapsed = window.innerWidth < this._md || !!parseInt(localStorage.getItem('isSiderOpened'), 10);
  }

  ngOnInit(): void {
    this.isCollapsed = !!parseInt(localStorage.getItem('isSiderOpened'), 10);
    registerLocaleData(localeDe, 'de');
    dayjs.locale('de');
    this._identityService.populate();
    this.workstationMode = this._jwtService.getWorkstationMode();
    this._titleService.populate();
    if (this._swUpdate.isEnabled) {
      this._swUpdate.versionUpdates.pipe(
        filter(event => event.type === 'VERSION_READY')
      ).subscribe( () => this.showNewVersion());
    }
    this._apiService.getVersion().subscribe((version) => {
      this.version = `${environment.version}.${version.active}`;
      if (version.prev !== null && version.prev !== '') {
        this.showNewVersion();
      }
    });
    this.initExportWebSocket();
    this._identityService.isAuthenticated.subscribe(this.setAppDetails.bind(this));

    if ('ontouchstart' in window) {
      fromEvent<KeyboardEvent>(document, 'keydown').pipe(
        takeUntilDestroyed(this._destroyRef)
      ).subscribe(e => {
        const textInput = e.key || String.fromCharCode(e.keyCode);
        const targetName = e.target['localName'];

        if (textInput.length === 1 && targetName !== 'input') {
          this._scannedVal += textInput;

          if (this._timer) {
            clearTimeout(this._timer);
          }

          this._timer = setTimeout(() => {
            if (this._scannerService.isMainScannerEnabled) {
              this.searchChanged(this._scannedVal);
            } else {
              this._scannerService.scannedVal$.next(this._scannedVal);
            }

            this._scannedVal = '';
            this._cdr.detectChanges();
          }, 100);
        }

      });
    }
  }

  openModal(key: string, param: any = null): void {
    this._globalModalService.openModal(key, param);
  }

  public openElement(i: number): void {
    this.menuItems.forEach(item => item.isOpen = false);
    this.menuItems[i].isOpen = true;
  }

  public openSidebar() {
    this.isCollapsed = !this.isCollapsed;
    localStorage.setItem('isSiderOpened', this.isCollapsed ? '1' : '0');
  }

  public refreshOrdersList(): void {
    if (this.activatedComponent instanceof OrdersComponent) {
      this.activatedComponent.listComponent.onRefreshList();
    }
  }

  public searchChanged(val: string): void {
    const prefix = val.slice(0, 2);
    if (prefix === DocumentCodes.ORDER) {
      this.addEditOrderModalComponent.showModal({ id: parseInt(val.slice(2), 10) } as any);
    } else if (prefix === DocumentCodes.PRODUCTION_ORDER) {
      this.openModal('productionOrderProcessModal');
      this.processModalComponent.orderNumber = val.slice(2);
      setTimeout(() => this.processModalComponent.handleNumberScan(val.slice(2)));
    } else if (prefix === DocumentCodes.DELIVERY_NOTE) {
      this.openModal('deliveryNoteProcessModal');
      this.deliveryNoteProcessModalComponent.orderNumber = val;
      this.deliveryNoteProcessModalComponent.handleNumberScan(val);
    } else if (val.length === 13) {
      this.openModal('storagePlaceFinderModal');
      this.storagePlaceFinderModalComponent.eanCode = val;
      this.storagePlaceFinderModalComponent.handleNumberScan();
    } else if (prefix === DocumentCodes.STOCK_TRANSFER) {
      this._flourService.getPackingListById(+val.slice(2)).subscribe(res => {
        setTimeout(() => {
          const type = res.isOutgoingDoc ? 1 : 0;
          this.openModal('stockTransferProcessModal', { type });
          this.stockTransferProcessModal.transferNumber = val.slice(2);
          setTimeout(() => this.stockTransferProcessModal.handleNumberScan(val.slice(2) + '\n'));
          this.searchModalComponent.closeModal();
        });
      }, () => this._notificationService.pushError('Fehler', 'Fehler beim Abrufen der Packliste.'));
    } else {
      this._notificationService.pushError('Fehler', 'Barcode / ean ungültig.');
      return;
    }

    if (prefix !== DocumentCodes.STOCK_TRANSFER) {
      this.searchModalComponent.closeModal();
    }
  }

  private initExportWebSocket(): void {
    this._exportWebSocketService.start();
    this._exportWebSocketService.exportDocumentGenerated(({ operationId, response, hasError }) => {
      if (this._exportService.operationIdExists(operationId)) {
        if (!hasError) {
          this._exportService.exportGenerated = response;
          this._exportService.removeOperationId(operationId);
          this._notificationService.pushSuccess('Erfolg', `Das Exportdokument kann heruntergeladen werden. <a href="/tools/exports">Zu Exporten gehen</a>`);

          if (this.activatedComponent instanceof ExportsPageComponent) {
            this.activatedComponent.exportListViewComponentRef.onRefreshList(false);
          }
        } else {
          this._notificationService.pushError('Fehler', 'Beim Generieren des Exportdokuments ist ein Fehler aufgetreten');
        }
      }
    });
  }

  setAppDetails(val?: boolean) {
    if (val && this.showBannerAutologout) {
      this.showBannerAutologout = false;
    }
    this.getUserDetails(val || null);
  }

  getUserDetails(val?: boolean) {
    this.currentUser = val ? this._jwtService.getIdentity().claims : null;
    if (this.currentUser) {
      this._localStorafeSrvice.setLocalStorageItemKey(this.currentUser.userID);
    }
  }

  changeMode(workStationMode: boolean) {
    this._jwtService.setWorkstationMode(workStationMode);
    if (!workStationMode) {
      this._localStorafeSrvice.removeItem('currentPage');
      this._localStorafeSrvice.setLocalStorageItemKey();
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    } else {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      }
      this._localStorafeSrvice.setLocalStorageItemKey(this.currentUser.userID);
      this.saveNavagationEnd(this._router.url);
    }
  }

  saveNavagationEnd(currentPage) {
    if (this._jwtService.getWorkstationMode() && !['login', 'logout'].some(element => currentPage.includes(element)) && this.currentUser) {
      this._localStorafeSrvice.item = {
        key: 'currentPage',
        value: currentPage
      };
    }
  }

  showBanner() {
    this.showBannerAutologout = true;
  }

  showNewVersion() {
    if (confirm('Neue Version verfügbar. Neue Version laden?')) {
      window.location.reload();
    }
  }

  public refreshStockTransfersList(): void {
    if (this.activatedComponent instanceof StockTransferComponent) {
      this.activatedComponent.listComponent.onRefreshList();
    }
  }
}
