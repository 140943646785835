<nz-spin [nzSpinning]="loading">
  <nz-row>
    <form nz-form [formGroup]="form" (ngSubmit)="submitForm()" style="width: 100%">
      <nz-form-item>
        <nz-form-label [nzXs]="24" style="text-align: left">
          <span>Rezept</span>
        </nz-form-label>
        <nz-form-control [nzXs]="24" [nzSm]="12">
          <ul nz-list [nzDataSource]="selectedArticles" nzBordered nzSize="large">
            <nz-list-header>ausgewählte Artikel</nz-list-header>
            <li nz-list-item *ngFor="let item of selectedArticles" nzNoFlex>
              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <a (click)="removeFromList(item)">X</a>
                </nz-list-item-action>
              </ul>
              {{ item.ingredientArticle.value }}
            </li>
          </ul>
        </nz-form-control>
        <nz-form-control [nzXs]="24" [nzSm]="12">
          <ul nz-list [nzDataSource]="articles" nzBordered nzSize="large" *ngIf="articles$|async as articles">
            <nz-list-header>alle Artikel   <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar></nz-list-header>
            <li nz-list-item *ngFor="let item of articles" nzNoFlex>
              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <a (click)="addToList(item)"><-</a>
                </nz-list-item-action>
              </ul>
              {{ item.ingredientArticle.value }}
            </li>
          </ul>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-row>
</nz-spin>
