<nz-layout class="app-layout">
  <nz-sider
          *appShowAuthed="true"
          nzCollapsible
          [nzTrigger]="null"
          class="menu-sidebar"
          nzTheme="light"
          nzWidth="256px"
          [(nzCollapsed)]="isCollapsed"
  >
    <div class="sidebar-logo">
      <a routerLink="/">
        <img src="/assets/img/logo.svg" alt="logo">
      </a>
    </div>
    <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed" class="menu">
      <ng-container *ngFor="let item of menuItems; let i = index;">

        <li nz-menu-item nzMatchRouter [nzMatchRouterExact]="item.exact" *ngIf="!item.elements">
          <a
            [routerLink]="item.routerLink | routerLink"
            *ngIf="!item.showModal"
            [queryParams]="item.routerLink | queryParams"
          >
            <i nz-icon nzType="{{item.icon}}"></i>
            <span>{{item.name}}</span>
          </a>
          <a (click)="openModal(item.modalRef)" *ngIf="item.showModal">
            <i nz-icon nzType="{{item.icon}}"></i>
            <span>{{item.name}}</span>
          </a>
        </li>

        <li
          *ngIf="item.elements"
          nz-submenu
          [nzOpen]="item.isOpen && !isCollapsed"
          nzTitle="{{item.name}}"
          nzIcon="{{item.icon}}"
          (click)="openElement(i)">
          <ul>
            <li nz-menu-item nzMatchRouter *ngFor="let subitem of item.elements">
              <a
                [routerLink]="subitem.routerLink | routerLink"
                [queryParams]="subitem.routerLink | queryParams"
                *ngIf="!subitem.showModal"
              >{{subitem.name}}</a>
              <a (click)="openModal(subitem.modalRef, subitem.modalParam)" *ngIf="subitem.showModal">{{subitem.name}}</a>
            </li>
          </ul>
        </li>
      </ng-container>

    </ul>
    <div class="version text-center">
      {{ version }}
    </div>
  </nz-sider>
  <nz-layout class="nz-layout-wrapper">
    <nz-alert *ngIf="showBannerAutologout" nzBanner nzMessage="Sie wurden automatisch abgemeldet. Bitte melden Sie sich erneut an." nzCloseable></nz-alert>

    <nz-header *appShowAuthed="true">
      <div class="app-header">
        <span class="header-trigger" (click)="openSidebar()">
            <i class="trigger"
               nz-icon
               [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></i>
        </span>

        <div class="app-header__options">
          <app-countdown *ngIf="workstationMode" (timeout)="showBannerAutologout = true"></app-countdown>
          <span class="workstation-toggle">
            Workstation mode:
            <nz-switch [(ngModel)]="workstationMode" (ngModelChange)="changeMode($event)"></nz-switch>
          </span>
          <i nz-icon nzType="barcode" nzTheme="outline" class="scan-button" (click)="openModal('searchModal')"></i>
          <span class="avatar-header" nz-dropdown [nzDropdownMenu]="menu" [nzDisabled]="!currentUser" [nzTrigger]="'click'">
            <nz-avatar [nzSize]="40" nzIcon="user" *ngIf="!currentUser"></nz-avatar>
            <app-avatar
              *ngIf="currentUser"
              [sizePx]="40"
              [fullName]="{ firstName: currentUser.firstName, lastName: currentUser.lastName }"
            ></app-avatar>
          </span>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>
                <a (click)="openModal('editMyProfileModal')">Profil bearbeiten</a>
              </li>
              <li nz-menu-item>
                <a routerLink="auth/logout">Ausloggen</a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>


    </nz-header>
    <nz-content>
        <router-outlet (activate)="activatedComponent = $event"></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>


<app-process-modal globalModal="productionOrderProcessModal" #processModalComponent>
  <span trigger></span>
</app-process-modal>

<app-delivery-note-process-modal globalModal="deliveryNoteProcessModal" #deliveryNoteProcessModalComponent></app-delivery-note-process-modal>

<app-stock-transfer-process-modal globalModal="stockTransferProcessModal" #stockTransferProcessModal (refresh)="refreshStockTransfersList()">
  <span trigger></span>
</app-stock-transfer-process-modal>

<app-change-mhd-modal globalModal="changeMhdModal" />

<app-storage-place-finder-modal globalModal="storagePlaceFinderModal" #storagePlaceFinderModalComponent></app-storage-place-finder-modal>
<app-article-variants-stocks-preview-modal globalModal="articleVariantsStocksPreviewModal" />

<app-edit-my-profile-modal globalModal="editMyProfileModal" (refresh)="getUserDetails(true)"></app-edit-my-profile-modal>

<app-search-modal
  globalModal="searchModal"
  #searchModalComponent
  (searchChanged)="searchChanged($event)"
  *appShowAuthed="true"
></app-search-modal>

<app-add-edit-order-modal #addEditOrderModalComponent (refresh)="refreshOrdersList()">
  <span trigger></span>
</app-add-edit-order-modal>
