import { OrderTypes } from '../app/pages/bulk-containers/core/enums';
import { MenuItem } from '../app/core/models/menu-item.model';

export const menuItems: MenuItem[] = [
  {
    name: 'Dashboard',
    icon: 'pie-chart',
    routerLink: '',
    isMobile: false,
    exact: true
  },
  {
    name: 'Artikel',
    icon: 'tags',
    elements: [
      {
        name: 'Übersicht',
        routerLink: '/articles',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Lagerplätze',
        isMobile: false,
        exact: true,
        showModal: true,
        modalRef: 'storagePlaceFinderModal'
      },
      {
        name: 'Bestände Vorschau',
        isMobile: false,
        exact: true,
        showModal: true,
        modalRef: 'articleVariantsStocksPreviewModal'
      },
      {
        name: 'EAN',
        routerLink: '/ean',
        isMobile: false,
        exact: true,
      },
      {
        name: 'MHD',
        isMobile: false,
        exact: true,
        showModal: true,
        modalRef: 'changeMhdModal',
      },
      {
        name: 'Etiketten',
        routerLink: '/labels-printer',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Rezeptkarten',
        routerLink: '/recipe-cards-printer',
        isMobile: false,
        exact: false,
      }
    ]
  },
  {
    name: 'Logistik',
    icon: 'shopping-cart',
    elements: [
      {
        name: 'Offene Bestellungen',
        routerLink: '/orders/open',
        isMobile: false,
        exact: true
      },
      {
        name: 'Heute fertiggestellt',
        routerLink: '/orders/closed-today',
        isMobile: false,
        exact: true
      },
      {
        name: 'Bestellung versenden',
        isMobile: false,
        exact: true,
        showModal: true,
        modalRef: 'deliveryNoteProcessModal'
      },
      {
        name: 'Reklamationen',
        routerLink: '/complaints',
        isMobile: false,
        exact: true
      }
    ],
    isOpen: false
  },
  {
    name: 'Lagerhäuser',
    icon: 'database',
    elements: [
      {
        name: 'Warentransfer München',
        routerLink: '/flour/transfers-munich',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Warentransfer Nürnberg',
        routerLink: '/flour/transfers-nuremberg',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Warentransfer Hechendorf',
        routerLink: '/flour/transfers-hechendorf',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Warentransfer Salzburg',
        routerLink: '/flour/transfers-salzburg',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Warentransfer Münster',
        routerLink: '/flour/transfers-munster',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Umlagerungen',
        routerLink: '/stock-transfer',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Eingang',
        isMobile: false,
        exact: true,
        showModal: true,
        modalRef: 'stockTransferProcessModal',
        modalParam: {type: 1}
      },
      {
        name: 'Ausgang',
        isMobile: false,
        exact: true,
        showModal: true,
        modalRef: 'stockTransferProcessModal',
        modalParam: {type: 0}
      },
    ],
    isOpen: false
  },
  {
    name: 'Produktion',
    icon: 'build',
    elements: [
      {
        name: 'Übersicht',
        routerLink: '/storestocks',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Produktions-/Bestellplanung',
        routerLink: '/planning-production',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Großgebinde',
        routerLink: '/bulk-containers/overview',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Non-Packaging',
        routerLink: '/bulk-containers/non-packing',
        isMobile: false,
        exact: true,
      },
      // {
      //   name: 'Bestellungen',
      //   routerLink: `/bulk-containers/orders?orderType=${ OrderTypes.MIXED }`,
      //   isMobile: false,
      //   exact: true,
      // },
      {
        name: 'Bestellungen - Packaging',
        routerLink: `/bulk-containers/orders?orderType=${ OrderTypes.Bagable }`,
        isMobile: false,
        exact: true,
      },
      {
        name: 'Bestellungen - Non-packaging',
        routerLink: `/bulk-containers/orders?orderType=${ OrderTypes.NonPacking }`,
        isMobile: false,
        exact: true,
      },
      {
        name: 'Mischungen',
        routerLink: '/own-production',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Geschenkbox',
        routerLink: '/giftbox-production',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Produktionsaufträge',
        routerLink: '/production-orders',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Produktionsaufträge produzieren',
        isMobile: false,
        exact: true,
        showModal: true,
        modalRef: 'productionOrderProcessModal'
      },
    ],
    isOpen: false
  },
  {
    name: 'Reports',
    icon: 'file',
    elements: [
      {
        name: 'Doppelte EAN Codes',
        routerLink: '/reports/duplicated-ean',
        exact: true,
      },
      {
        name: 'Flour Übersicht',
        routerLink: '/reports/flour-review',
        exact: true,
      }
    ],
    isOpen: false
  },

  {
    name: 'Marketing',
    icon: 'pie-chart',
    elements: [
      {
        name: 'E-Mail-Unterschrift',
        routerLink: '/marketing/email-signature',
        exact: true,
      },
      {
        name: 'Geschenkgutscheine',
        routerLink: '/marketing/vouchers',
        exact: true,
      },
    ],
    isOpen: false
  },
  {
    name: 'Seminare',
    icon: 'calendar',
    elements: [
      {
        name: 'Übersicht',
        routerLink: '/seminars/overview',
        exact: true,
      },
      {
        name: 'Anmeldungen',
        routerLink: '/seminars/orders',
        exact: true,
      },
      {
        name: 'Kategorien',
        routerLink: '/seminars/categories',
        exact: true,
      },
      {
        name: 'Warteraum',
        routerLink: '/seminars/waiting-room',
        exact: true,
      },
      {
        name: 'Seminare Hechendorf',
        routerLink: '/seminars/hechendorf',
        exact: true,
      },
      {
        name: 'Seminare München',
        routerLink: '/seminars/munchen',
        exact: true,
      },
      {
        name: 'Seminare Nürnberg',
        routerLink: '/seminars/nurnberg',
        exact: true,
      },
      {
        name: 'Seminare Salzburg',
        routerLink: '/seminars/salzburg',
        exact: true,
      },
      {
        name: 'Seminare Münster',
        routerLink: '/seminars/muenster',
        exact: true,
      },
    ],
    isOpen: false
  },
  {
    name: 'Controlling',
    icon: 'control',
    elements: [
      {
        name: 'Lagerbestand',
        routerLink: '/controlling/stocks',
        exact: true,
      },
      {
        name: 'Produktion',
        routerLink: '/controlling/production',
        exact: true,
      },
      {
        name: 'Großgebinde',
        routerLink: '/controlling/bulk-container',
        exact: true
      },
      {
        name: 'Non-Packaging',
        routerLink: '/controlling/non-packing',
        exact: true
      },
      {
        name: 'Preise',
        routerLink: '/controlling/prices',
        exact: true
      },
      {
        name: 'Kosten',
        routerLink: '/controlling/costs',
        exact: true
      },
      {
        name: 'Umsatz',
        routerLink: '/controlling/sales-evaluation',
        exact: true
      }
    ],
    isOpen: false
  },
  {
    name: 'Einstellungen',
    icon: 'setting',
    elements: [
      {
        name: 'Allgemein',
        routerLink: '/settings/general',
        exact: true,
      },
      {
        name: 'Mitarbeiter',
        routerLink: '/users',
        exact: true,
      },
      {
        name: 'Lieferanten',
        routerLink: '/suppliers',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Lagerhäuser',
        routerLink: '/stores',
        isMobile: false,
        exact: true,
      },
    ],
    isOpen: false
  },
  {
    name: 'Werkzeuge',
    icon: 'tool',
    elements: [
      {
        name: 'Linkverkürzer',
        routerLink: '/tools/link-shortener',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Exporte',
        routerLink: '/tools/exports',
        exact: true,
      },
    ]
  }
];
