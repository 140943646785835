<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  nzTitle="Preview Stocks"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  nzWidth="900px"
>

  <ng-template #modalContent>
    <div class="location-number-field">
      Produktname:
      <app-search
        [showBarcodeScanner]="false"
        [clearCodeAfterChange]="false"
        (searchChanged)="onSearchChanged($event)"
      ></app-search>
    </div>

    <nz-divider></nz-divider>
    <nz-row>
      <nz-col nzFlex="24">
        <nz-table
          *ngIf="stores$ | async as stores"
          nzShowSizeChanger
          [nzFrontPagination]="false"
          [nzLoading]="isLoading()"
          [nzData]="data"
          nzSize="small"
        >
          <thead>
          <tr>
            <th nzColumnKey="name" nzWidth="300px">Produkt</th>
            <th nzColumnKey="ean">EAN</th>
            <ng-container *ngFor="let store of stores">
              <th *ngIf="store | isStoreMainOrBbm">
                {{ store.value }}
              </th>
            </ng-container>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let variant of data">
            <td>{{ variant.fullName }}</td>
            <td>{{ variant.ean }}</td>
            <ng-container *ngFor="let store of stores">
              <td *ngIf="store | isStoreMainOrBbm">
                {{ variant.stocks | stockByStore:store.value }}
              </td>
            </ng-container>
          </tr>
          </tbody>
        </nz-table>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
  </ng-template>
</nz-modal>
