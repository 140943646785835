<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
  (nzAfterOpen)="afterModalOpen()"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>

    <div class="ean-field">
      EAN Code: <input [(ngModel)]="eanCode" (ngModelChange)="handleNumberScan()" appPossitiveNumbers #scanField nz-input>
      &nbsp;<button nz-button nzType="primary">
        <i nz-icon nzType="barcode" nzTheme="outline" (click)="handleBarcodeScanner()"></i>
      </button>
    </div>
    <div class="product-name-field">
      Produktname: <input [(ngModel)]="productName" (ngModelChange)="handleProductNameChange()" nz-input>
    </div>
    <div class="location-number-field">
      Lagerplatz: <input [(ngModel)]="location" (ngModelChange)="handleLocationChange()" nz-input>
    </div>

    <nz-spin [nzSpinning]="loading">
      <nz-divider></nz-divider>
      <nz-row >
        <nz-col nzFlex="24">
          <nz-table
            nzShowSizeChanger
            [nzData]="locations"
            [nzShowPagination]="false"
          >
            <thead>
            <tr>
              <th>Produktname</th>
              <th>EAN</th>
              <th>Bestellnummer</th>
              <th>MHD</th>
              <th>Lager</th>
              <th>Lagerplatz</th>
              <th>Stock</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let location of locations">
              <tr *ngFor="let store of location.stores">
                <td data-name="Produktname">{{ location.articleName }} {{ location.variantName }}</td>
                <td data-name="EAN">{{ location.ean }}</td>
                <td data-name="Bestellnummer">{{ location.orderNumber }}</td>
                <td data-name="MHD" style="white-space: nowrap;">
                  {{ location.mhd | dateFormat }}
                  <button nz-button nzType="text" (click)="openQuickEditModal(location)">
                    <i nz-icon nzType="edit"></i>
                  </button>
                </td>
                <td data-name="Store">{{ store.value }}</td>
                <td data-name="Lagerplatz">{{ store.location }}</td>
                <td data-name="Stock">{{ store.stock }}</td>
              </tr>
            </ng-container>
            </tbody>
          </nz-table>
        </nz-col>
      </nz-row>
    </nz-spin>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
  </ng-template>
</nz-modal>

<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isQuickEditVisible"
  [nzTitle]="quickEditTitle"
  [nzContent]="quickEditContent"
  [nzFooter]="quickEditFooter"
  (nzOnCancel)="handleQuickEditCancel()"
  [nzWidth]="400"
>
  <ng-template #quickEditTitle>Schnell MHD ändern</ng-template>

  <ng-template #quickEditContent>
    <nz-spin [nzSpinning]="loading">
      <div *ngIf="selectedLocation">
        <div class="mb-4">{{ selectedLocation.articleName }} {{ selectedLocation.variantName }}</div>
        <nz-date-picker
          style="width: 100%;"
          [nzFormat]="dateFormat"
          [(ngModel)]="quickEditMhd"
        />
      </div>
    </nz-spin>
  </ng-template>

  <ng-template #quickEditFooter>
    <button nz-button nzType="default" (click)="handleQuickEditCancel()" [disabled]="loading">Abbrechen</button>
    <button nz-button nzType="primary" (click)="saveQuickEditMhd()" [nzLoading]="loading">Speichern</button>
  </ng-template>
</nz-modal>
