import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AddEditFormGeneric } from '../../../ui/generics';
import { IArticle, IArticleVariant, ITax } from '../../../core/interfaces';
import { ConfirmationModalComponent } from '../../../ui/modals/confirmation-modal/confirmation-modal.component';
import { forkJoin, Observable } from 'rxjs';
import { IFormulaIngredient } from '../../../pages/own-production/core/interfaces/IFormulaIngredient';
import { IGiftboxFormula } from '../../../pages/giftbox-production/core/interfaces/IGiftboxFormula';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { FormBuilder, Validators } from '@angular/forms';
import { ArticlesService } from '../../../pages/articles/core/ArticlesService';
import { NotificationService } from '../../../core/services';
import {
  ArticlesServiceErrorResolverService
} from '../../../pages/articles/core/error-resolvers/articles-service-error-resolver.service';
import { OwnProductionService } from '../../../pages/own-production/core/OwnProductionService';
import { GiftboxesService } from '../../../pages/giftbox-production/core/services/GiftboxesService';
import { StoresService } from '../../../pages/stores/core/services/StoresService';
import { SuppliersService } from '../../../pages/suppliers/core/services/SuppliersService';
import { finalize, map, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { ToolsService } from '../../../core/services/ToolsService';
import { IsStoreMainOrBbmPipe } from '../../../core/pipes';

@Component({
  selector: 'app-add-edit-article-form',
  templateUrl: './add-edit-article-form.component.html',
  styleUrls: ['./add-edit-article-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    IsStoreMainOrBbmPipe
  ]
})
export class AddEditArticleFormComponent extends AddEditFormGeneric<IArticle> implements OnInit {

  @ViewChild('confirmationModalComponent') confirmationModalComponent: ConfirmationModalComponent;

  @Output() articleCreated = new EventEmitter<IArticle>();

  public articles$: Observable<IFormulaIngredient[]>;
  public giftboxFormulaArticles$: Observable<IGiftboxFormula[]>;
  public selectedArticles: IFormulaIngredient[] = [];
  public selectedGiftboxArticles: IGiftboxFormula[] = [];
  public currentSearch: string;
  public suppliers$: Observable<ISimpleListItem[]>;
  public taxes$: Observable<ITax[]>;
  public gitboxSearch = '';
  public ownProductionSearch = '';
  public hasVariants: boolean;
  public stores$: Observable<ISimpleListItem[]>;
  public isSetDiscountPriceModalVisible = false;
  public variantToSetDiscountPrice: IArticleVariant;
  public selectedDiscountValue: number;
  public showManualPrice = false;

  private _article: IArticle;

  constructor(
    protected readonly _fb: FormBuilder,
    protected readonly _service: ArticlesService,
    protected readonly _notifyService: NotificationService,
    protected readonly _errorResolver: ArticlesServiceErrorResolverService,
    private readonly _ownProductionService: OwnProductionService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _giftboxesService: GiftboxesService,
    private readonly _storeService: StoresService,
    private readonly _isStoreMainOrBbmPipe: IsStoreMainOrBbmPipe,
    private readonly _suppliersService: SuppliersService,
    private readonly _toolsService: ToolsService,
  ) {
    super(_fb, _service, _notifyService, _errorResolver);

    this.form = this._fb.group({
      name: ['', Validators.required],
      shortDescription: ['', Validators.required],
      orderNumber: ['', Validators.required],
      supplierId: [null, Validators.required],
      ean: [''],
      weight: [0],
      purchaseUnit: [0],
      packUnit: [null],
      hasVariants: [false],
      isBio: [false],
      isOwnProduction: [false],
      isGiftbox: [false],
      noWeightForProduction: [false],
      isBagable: [false],
      isNonPackable: [false],
      taxId: [null, Validators.required]
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.taxes$ = this._toolsService.getTaxes();
    this.stores$ = this._storeService.getSimple();
    this.giftboxFormulaArticles$ = this._service.getArticlesVariants('', true).pipe(
      map(this.castSimpleListToGiftbox)
    );
    this.articles$ = this._service.getSimpleList('', true).pipe(
      map(this.castSimpleListToIngredient)
    );
  }

  public submitForm(): void {
    this.isSubmitted = true;
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      }
    }

    if (!this.form.valid) {
      this.formFinished.emit(false);
      return;
    }

    this._article = this.form.value;
    this._article.ean = this._article.ean ? this._article.ean.toString() : this._article.ean;

    !this.isEdit ? this.saveNew() : this.saveEdit();
  }

  public saveNew(): void {
    this._formService.create(this._article).subscribe(articleId => {
      this._service.getById(articleId).subscribe(res => this.articleCreated.emit(res));
      this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt erstellt`);
      this.formFinished.emit(true);
    }, err => {
      this.parseError(err);
      this.formFinished.emit(false);
    });
  }

  public saveEdit(): void {
    const { isOwnProduction: newIsOwnProduction } = this.form.value;
    const { isOwnProduction: oldIsOwnProduction } = this.editObject;
    const values = this.form.getRawValue();
    const updateArticleApiCall = this._formService.update(
      {
        isBagable: values['isBagable'],
        isNonPackable: values['isNonPackable'],
        isOwnProduction: values['isOwnProduction'],
        noWeightForProduction: values['noWeightForProduction'],
        taxId: values['taxId']
      } as IArticle,
      this.editObject['id']
    );
    const updateFormulaIngredientsApiCall = this._ownProductionService.updateFormulaIngredientsByArticleId(
      this.editObject.id,
      { articleFormulaIngredients: this.selectedArticles }
    );
    const apiCalls = [updateArticleApiCall, updateFormulaIngredientsApiCall];

    if (oldIsOwnProduction && newIsOwnProduction) {
      forkJoin(apiCalls).subscribe({
        next: this.pushSaveFormSuccess.bind(this),
        error: this.pushSaveFormError.bind(this)
      });
    } else if (oldIsOwnProduction && !newIsOwnProduction) {
      updateArticleApiCall.subscribe({
        next: this.pushSaveFormSuccess.bind(this),
        error: this.pushSaveFormError.bind(this)
      });
    } else if (!oldIsOwnProduction && newIsOwnProduction) {
      updateArticleApiCall.subscribe({
        next: () => {
          updateFormulaIngredientsApiCall.subscribe({
            next: this.pushSaveFormSuccess.bind(this),
            error: this.pushSaveFormError.bind(this)
          });
        },
        error: this.pushSaveFormError.bind(this)
      });
    } else {
      updateArticleApiCall.subscribe({
        next: this.pushSaveFormSuccess.bind(this),
        error: this.pushSaveFormError.bind(this)
      });
    }
  }

  public removeOwnProductionFromList(i: number): void {
    this.selectedArticles = this.selectedArticles.filter((item, id) => id !== i);
    this._cdr.detectChanges();
  }

  public addOwnProductionToList(obj: IFormulaIngredient): void {
    obj.id = 0;
    obj.ingredientArticle.id = obj.ingredientArticleId;
    this.selectedArticles = [...this.selectedArticles, obj];
    this._cdr.detectChanges();
  }

  public searchOwnProduction(phrase: string): void {
    this.currentSearch = phrase;
    this.articles$ = null;
    this.articles$ = this._service.getSimpleList(phrase, true).pipe(map(this.castSimpleListToIngredient));
  }

  public removeGiftboxFromList(i: number): void {
    this.selectedGiftboxArticles = this.selectedGiftboxArticles.filter((item, id) => id !== i);
    this._cdr.detectChanges();
  }

  public addGiftboxToList(obj: IGiftboxFormula): void {
    obj.id = -1;
    this.selectedGiftboxArticles = [...this.selectedGiftboxArticles, obj];
    this._cdr.detectChanges();
  }

  public searchGiftboxArticles(phrase: string): void {
    this.gitboxSearch = phrase;
    this.giftboxFormulaArticles$ = null;
    this.giftboxFormulaArticles$ = this._service.getArticlesVariants(phrase, true).pipe(map(this.castSimpleListToGiftbox));
  }

  public castSimpleListToIngredient(value: ISimpleListItem[]): IFormulaIngredient[] {
    return value.map(ing => ({
      id : -1,
      articleId: -1,
      ingredientArticleId: parseInt(ing.id, 10),
      ingredientArticle: {
        value: `${ing.value}`,
        id: -1,
      }
    }));
  }

  public beforeLoadForm(): void {
    super.beforeLoadForm();

    if (this.editObject) {
      this.fetchDictionaries();

      this.hasVariants = this.editObject.hasVariants;
      this.editObject.articleVariants.unshift(this.editObject.defaultArticleVariant);
      this.form.get('supplierId').patchValue(this.editObject.supplierId?.toString());
      this.form.get('packUnit').patchValue(this.editObject.defaultArticleVariant.packUnit);
      this.form.get('weight').patchValue(this.editObject.defaultArticleVariant.weight);
      this.form.get('purchaseUnit').patchValue(this.editObject.defaultArticleVariant.purchaseUnit);
      this.form.get('ean').patchValue(this.editObject.defaultArticleVariant.ean);

      if (this.editObject.isOwnProduction) {
        this._ownProductionService.getFormulaIngredientsByArticleId(this.editObject.id).subscribe(value => {
          this.selectedArticles = value;
        });
      }

      if (this.editObject.isGiftbox) {
        this._giftboxesService.getFormulaGiftBox(this.editObject.defaultArticleVariant.id).subscribe(value => {
          this.selectedGiftboxArticles = value;
        });
      }

      this.editObject.articleVariants.forEach(x => {
        x.price = !x.price ? { price: null, pseudoPrice: null } : x.price;
      });
    }
  }

  public removeVariantsChoice(choice: boolean): void {
    this.form.get('hasVariants').patchValue(!choice);
    this.hasVariants = !choice;
  }

  public checkedChanged(): void {
    if (this.isEdit) {
      if (this.hasVariants && !this.form.get('hasVariants').value) {
        this.confirmationModalComponent.showModal();
      } else if (!this.hasVariants && this.form.get('hasVariants').value) {
        this.hasVariants = true;
        this.form.get('hasVariants').patchValue(true);
      }
    }
  }

  public editSaveButtonClick(variant: IArticleVariant): void {
    variant.isEdited = !variant.isEdited;

    if (!variant.isEdited) {
      this.loading = true;

      forkJoin([
        this._service.setVariantPrice(
          variant.id,
          {
            price: variant.price?.price,
            pseudoPrice: variant.price?.pseudoPrice,
          }
        ),
        this._service.updateArticleVariant(
          {
            isActiveForProduction: variant.isActiveForProduction,
            articleId: variant.articleId,
            storageLocationNumber: variant.storageLocationNumber,
            storageInfos: variant.storeLocations.map(x => {
              return {
                storeId: x.store.id,
                storageLocationNumber: x.storageLocation
              };
            }),
            customWeight: variant.customWeight,
            isNotRelevantForPacking: variant.isNotRelevantForPacking,
          },
          variant.id
        )
      ]).pipe(
        finalize(() => {
          this.loading = false;
          this._cdr.detectChanges();
        })
      ).subscribe(() => {
        this._notifyService.pushSuccess('Erfolg', 'Variant korrekt aktualisiert.');
      }, () => {
        this._notifyService.pushError('Fehler', 'Fehler beim Aktualisieren der Variant.');
      });
    }
  }

  public cancelSetDiscountPrice(): void {
    this.isSetDiscountPriceModalVisible = false;
    this.variantToSetDiscountPrice = null;
    this.showManualPrice = false;
    this.selectedDiscountValue = null;
  }

  public showSetDiscountPriceModal(variant: IArticleVariant): void {
    this.isSetDiscountPriceModalVisible = true;
    this.variantToSetDiscountPrice = variant;
  }

  public isNonPackableChange(): void {
    const isBagable = this.form.get('isBagable');
    const isOwnProduction = this.form.get('isOwnProduction');

    if (this.form.get('isNonPackable').value) {
      isBagable.disable();
      isOwnProduction.disable();
      isBagable.patchValue(false);
      isOwnProduction.patchValue(false);
    } else {
      isBagable.enable();
      isOwnProduction.enable();
    }
  }

  public disableIsNonPackable(): void {
    const isBagable = this.form.get('isBagable');
    const isOwnProduction = this.form.get('isOwnProduction');
    const isNonPackable = this.form.get('isNonPackable');

    if (isBagable.value || isOwnProduction.value) {
      isNonPackable.patchValue(false);
      isNonPackable.disable();
    } else {
      isNonPackable.enable();
    }
  }

  public submitDiscountPrice(): void {
    if (!this.variantToSetDiscountPrice.price.pseudoPrice) {
      this.variantToSetDiscountPrice.price.pseudoPrice = this.variantToSetDiscountPrice.price.price;
    }

    this.variantToSetDiscountPrice.price.price = this.showManualPrice
      ? this.selectedDiscountValue
      : (((100 - this.selectedDiscountValue) * this.variantToSetDiscountPrice.price.pseudoPrice) / 100);

    this.cancelSetDiscountPrice();
  }

  protected afterLoadForm(): void {
    super.afterLoadForm();

    this.form.disable();
    this.form.get('isOwnProduction').enable();
    this.form.get('isNonPackable').enable();
    this.form.get('isBagable').enable();
    this.form.get('noWeightForProduction').enable();
    this.form.get('taxId').enable();
    this.form.get('taxId').patchValue(this._editObject.tax.id);

    this.editObject.articleVariants.forEach(variant => {
      this.stores$.subscribe(stores => {
        stores.forEach(store => {
          if (this._isStoreMainOrBbmPipe.transform(store)) {
            if (!variant.storeLocations.find(x => x.store.id === +store.id)) {
              variant.storeLocations.push({
                store: {
                  id: +store.id,
                  name: store.value
                },
                storageLocation: null,
                articleVariantId: variant.id
              });
            }
          }
        });
      });
    });
  }

  private castSimpleListToGiftbox(items: ISimpleListItem[]): IGiftboxFormula[] {
    return items.map(({ id, value }) => {
      return {
        ingredientArticleVariantId: parseInt(id, 10),
        fullName: value
      };
    });
  }

  private fetchDictionaries(): void {
    this.suppliers$ = this._suppliersService.getSimpleList().pipe(
      tap(([{ id }]) => {
        this.form.get('supplierId').patchValue(!this.isEdit ? id : this.editObject.supplierId?.toString());
      })
    );
  }

  private pushSaveFormError(error: any): void {
    this.parseError(error);
    this.formFinished.emit(false);
  }

  private pushSaveFormSuccess(): void {
    this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt aktualisiert.`);
    this.formFinished.emit(true);
  }
}
