import { Pipe, PipeTransform} from '@angular/core';
import { ISimpleListItem } from '../interfaces/ISimpleListItem';

@Pipe({
  name: 'isStoreMainOrBbm'
})
export class IsStoreMainOrBbmPipe implements PipeTransform {

  public transform(store: ISimpleListItem, onlyBbm = false): boolean {
    if (!onlyBbm) {
      return store.additionalParameters['isMainStore'] === 'True' ||
        store.additionalParameters['isBbmStore'] === 'True';
    } else {
      return store.additionalParameters['isBbmStore'] === 'True';
    }
  }
}
