<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
></app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  appKeepState
  id="users-list-table"
>
  <thead>
  <tr>
    <th nzColumnKey="id" [nzSortFn]="true" nzWidth="75px">#ID</th>
    <th nzWidth="100px"></th>
    <th nzColumnKey="firstName" [nzSortFn]="true">Name</th>
    <th nzColumnKey="lastName" [nzSortFn]="true">Nachname</th>
    <th nzColumnKey="position" [nzSortFn]="true">Berufsbezeichnung</th>
    <th nzColumnKey="login" [nzSortFn]="true">Login</th>
    <th nzColumnKey="email" [nzSortFn]="true">Email</th>
    <th nzColumnKey="role" [nzFilters]="filterRoles" [nzFilterFn]="true" [nzSortFn]="true">Rolle</th>
    <th nzRight nzWidth="100px"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of data.results">
    <td>{{user.id}}</td>
    <td>
      <app-avatar
        [sizePx]="30"
        [fullName]="{ firstName: user.firstName, lastName: user.lastName }"
      ></app-avatar>
    </td>
    <td>{{user.firstName}}</td>
    <td>{{user.lastName}}</td>
    <td>{{user.position}}</td>
    <td>{{user.login}}</td>
    <td>{{user.email}}</td>
    <td>{{user.role}}</td>
    <td nzRight>
      <div class="actions">
        <app-add-edit-modal (refresh)="onRefreshList(false)" #modal>
        <span trigger>
          <i nz-icon nzType="edit"  nzTheme="outline" (click)="modal.showModal(user)"></i>
        </span>
        </app-add-edit-modal>
        <app-download-document [documentKey]="user.documentKey" *ngIf="user.documentKey" class="left-space">
          <span trigger>
            <i nz-icon nzType="file-pdf"  nzTheme="outline"></i>
          </span>
        </app-download-document>
        <span>
          <i nz-icon nzType="delete" nz-popconfirm nzPopconfirmTitle="Sind Sie sicher?" (nzOnConfirm)="deleteElement(user.id)"  nzTheme="outline"></i>
        </span>
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
