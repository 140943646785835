import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef, ViewContainerRef
} from '@angular/core';
import { D_GLOBAL_MODAL } from '../../../core/directives';
import { ModalGeneric } from '../../../ui/generics';
import { Subject, Subscription } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { debounceTime } from 'rxjs/operators';
import { IStorageLocation } from '../core/interfaces/IStorageLocation';
import { StoresService } from '../core/services/StoresService';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { IStoreStocksListItem } from '../../stores-stocks/core/interfaces/IStoreStocksListItem';
import { StoreStocksService } from '../../stores-stocks/core/services/StoreStocks.service';
import { QrcodeScannerComponent } from '../../../ui/qrcode-scanner/qrcode-scanner.component';
import { IArticleVariant, IStoreLocation } from 'src/app/core/interfaces/IArticleVariant';
import { ArticlesService } from '../../articles/core/ArticlesService';
import { NotificationService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-storage-place-finder-modal',
  templateUrl: './storage-place-finder-modal.component.html',
  styleUrls: ['./storage-place-finder-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: StoragePlaceFinderModalComponent }]
})
export class StoragePlaceFinderModalComponent extends ModalGeneric implements OnInit {

  @ViewChild('scanField') scanField: ElementRef<HTMLInputElement>;

  public eanCode = '';
  public location: string;
  public productName: string;
  public title = 'Lagerplatz finder';
  public locations: IStorageLocation[];
  public loading = false;
  private _stores: ISimpleListItem[] = [];
  private _valueChange$ = new Subject<void>();
  public isQuickEditVisible = false;
  public quickEditMhd: string;
  public selectedLocation: IStorageLocation;
  public readonly dateFormat = environment.defaultDateFormat;

  private _subs: Subscription[] = [];

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private readonly _modal: NzModalService,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _storeStocksService: StoreStocksService<IStoreStocksListItem>,
    private readonly _storesService: StoresService,
    private readonly _articlesService: ArticlesService,
    private readonly _notificationService: NotificationService
  ) {
    super(cdr);
  }

  handleProductNameChange = (): void => {
    this.eanCode = '';
    this.location = '';
    this._valueChange$.next();
  }

  handleNumberScan = (): void => {
    this.location = '';
    this.productName = '';
    this._valueChange$.next();
  }

  handleBarcodeScanner = (): void => this.createBarcodeScannerModal();

  afterModalOpen(): void {
    if (this.scanField) {
      this.setFocusOnScanField();
    }
  }

  private mapLocationsWithStores(locations: IStorageLocation[]): void {
    this.locations = locations.map(location => {
      location.stores = Object.keys(location).filter(key => key.includes('store') && key.includes('Value')).map(key => {
        return {
          location: location[key],
          ...this._stores.find(({ id }) => key.replace(new RegExp(/[store Value]/, 'g'), '') === id)
        };
      }).filter(store => this.location !== '' ? (store.id && store.location !== '') : store.id);

      return location;
    });

    this.loading = false;
    this.cdr.detectChanges();
  }

  private mapVariantStoreLocationsToStores(variants: IArticleVariant[]): void {
    this.locations = variants.map(variant => {
      const location: IStorageLocation = {
        articleId: variant.articleId,
        articleName: variant.articleName,
        articleVariantId: variant.id,
        variantName: variant.name,
        stores: variant.storeLocations.map(storeLocation => {
          return {
            location: storeLocation.storageLocation,
            stock: variant.stocks.find(stock => stock.stockId.toString() === storeLocation.store.id.toString())?.inStock,
            ...this._stores.find(({ id }) => storeLocation.store.id.toString() === id)
          };
        }).filter(store => this.location !== '' ? (store.id && store.location !== '') : store.id),
        ean: variant.ean,
        orderNumber: variant.orderNumber,
        mhd: variant.mhd,
        store1Value: '',
        store2Value: '',
        store3Value: '',
        store4Value: ''
      };

      return location;
    });
    this.loading = false;
    this.cdr.detectChanges();
  }

  showModal(object: any = null) {
    this._subs.push(
      this._storesService.getSimple()
        .subscribe(stores => {
          this._stores = stores;
          super.showModal(object);
        }),
      this._valueChange$.pipe(
        debounceTime(1000)
      ).subscribe(() => {
        this.loading = true;

        if (this.eanCode !== '') {
          this._storeStocksService.getAllStoreStocksByArticleName(this.eanCode)
            .subscribe((res: IArticleVariant[]) => {
              this.mapVariantStoreLocationsToStores(res);
            }, this.clearLocations.bind(this));
        } else if (this.productName !== '') {
          this._storeStocksService.getAllStoreStocksByArticleName(this.productName)
            .subscribe((res: IArticleVariant[]) => {
              this.mapVariantStoreLocationsToStores(res);
            }, this.clearLocations.bind(this));
        } else {
          this._storeStocksService.getProductDetailsByLocation(this.location)
            .subscribe(res => this.mapLocationsWithStores(res), this.clearLocations.bind(this));
        }

        this.cdr.detectChanges();
      })
    );
  }

  closeModal() {
    this._subs.forEach(sub => sub.unsubscribe());
    this._subs = [];
    this.location = '';
    this.productName = '';
    this.eanCode = '';
    super.closeModal();
  }

  createBarcodeScannerModal(): void {
    const modal = this._modal.create({
      nzTitle: 'Barcode scanner',
      nzContent: QrcodeScannerComponent,
      nzViewContainerRef: this._viewContainerRef,
      nzWidth: 689,
      nzFooter: [
        {
          label: 'Ok',
          type: 'primary',
          onClick: () => modal.destroy()
        }
      ]
    });
    modal.afterClose.subscribe(result => {
      this.eanCode = result?.code ?? '';
      this.handleNumberScan();
      this.cdr.detectChanges();
    });

    this.cdr.detectChanges();
  }

  setFocusOnScanField(): void {
    setTimeout(() => this.scanField.nativeElement.focus(), 100);
  }

  public handleLocationChange(): void {
    this.eanCode = '';
    this.productName = '';
    this._valueChange$.next();
  }

  public handleCancel(): void {
    this.eanCode = '';
    this.location = '';
    this.clearLocations();
    super.handleCancel();
  }

  private clearLocations(): void {
    this.locations = [];
    this.loading = false;
    this.cdr.detectChanges();
  }

  public openQuickEditModal(location: IStorageLocation): void {
    this.selectedLocation = location;
    this.quickEditMhd = location.mhd;
    this.isQuickEditVisible = true;
  }

  public handleQuickEditCancel(): void {
    this.isQuickEditVisible = false;
    this.quickEditMhd = null;
    this.selectedLocation = null;
  }

  public saveQuickEditMhd(): void {
    this.loading = true;

    this._articlesService.updateMHD(
      this.selectedLocation.articleVariantId,
      moment(this.quickEditMhd).format('YYYY-MM-DD')
    ).subscribe(() => {
      this.selectedLocation.mhd = this.quickEditMhd;
      this.isQuickEditVisible = false;
      this.quickEditMhd = null;
      this.selectedLocation = null;
      this._notificationService.pushSuccess('Erfolg', 'MHD korrekt aktualisiert.');
      this.cdr.detectChanges();
      this.loading = false;

    }, () => {
      this._notificationService.pushError('Fehler', 'Fehler beim Aktualisieren der MHD.');
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

}
