<nz-row style="flex-wrap: nowrap; position:relative; width: 100%;">
  <input nz-input #inputElement [(ngModel)]="code" (ngModelChange)="valueChanged()"/>
  <button
    nz-button
    nzType="primary"
    class="open-scanner-btn"
    (click)="createBarcodeScannerModal()"
    *ngIf="showBarcodeScanner"
  >
    <i nz-icon nzType="barcode" nzTheme="outline"></i>
  </button>
</nz-row>
