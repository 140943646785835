import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';

@Injectable({
  providedIn: 'root'
})
export class ArticleVariantConfigurationsService {

  private readonly _baseUrl = '/articleVariantConfigurations';

  constructor(
    private readonly _apiService: ApiService
  ) {
  }

  public getArticleVariantConfigurations(search = '', results = 10): Observable<ISimpleListItem[]> {
    const params = new HttpParams({
      fromObject: {
        search,
        results: results.toString()
      }
    });

    return this._apiService.get(`${ this._baseUrl }/simple`, params);
  }

  public getArticleVariantConfigurationById(id: number): Observable<{ id: number; name: string; isDeleted: boolean; }> {
    return this._apiService.get(`${ this._baseUrl }/${ id }`);
  }

  public createArticleVariantConfiguration(name: string): Observable<any> {
    return this._apiService.post(`${ this._baseUrl }`, { name });
  }
}
