import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockTransferRoutingModule } from './stock-transfer-routing.module';
import { StockTransferComponent } from './stock-transfer/stock-transfer.component';
import { ListComponent } from './list/list.component';
import { UiModule } from '../../ui/ui.module';
import { AddEditModalComponent } from './add-edit-modal/add-edit-modal.component';
import { AddEditFormComponent } from './add-edit-form/add-edit-form.component';
import { CoreModule } from '../../core/core.module';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { StockTransferProcessModalComponent } from './stock-transfer-process-modal/stock-transfer-process-modal.component';
import { StockTransferProcessFormComponent } from './stock-transfer-process-form/stock-transfer-process-form.component';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { DeliveryNoteErrorModalComponent } from './delivery-note-error-modal/delivery-note-error-modal.component';

@NgModule({
  declarations: [StockTransferComponent, ListComponent, AddEditModalComponent, AddEditFormComponent, StockTransferProcessModalComponent, StockTransferProcessFormComponent, DeliveryNoteErrorModalComponent],
  exports: [
    StockTransferProcessModalComponent,
    ListComponent,
    DeliveryNoteErrorModalComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    NzStatisticModule,
    NzTabsModule,
    StockTransferRoutingModule,
    UiModule,
    NzInputNumberModule,
    NzAlertModule
  ]
})
export class StockTransferModule { }
