import { IArticleVariant } from './IArticleVariant';
import { IBaseArticle } from './IBaseArticle';
import { IArticleLabelInfo } from './IArticleLabelInfo';
import { ITax } from './ITax';

export interface IArticle extends IBaseArticle {
  isGiftbox: boolean;
  hasVariants: boolean;
  isBagable: boolean;
  isNonPackable: boolean;
  shortDescription: string;
  articleVariants: IArticleVariant[];
  ean: string;
  tax?: ITax;
  taxId?: number;
  fullName?: string;
  purchaseUnit: number;
  articleLabelInfo: IArticleLabelInfo;
  isDeleted?: boolean;
  supplierId: number;
  ownProductionIngredients: number[];
  weight?: number;
  packUnit?: string;
  recipeDocumentKey?: string;
  mainArticleVariantId: string;
  defaultArticleVariant: IArticleVariant;
  noWeightForProduction: boolean;
}
