import { ChangeDetectionStrategy, ChangeDetectorRef, Component, signal } from '@angular/core';
import { ModalGeneric } from '../../../ui/generics';
import { D_GLOBAL_MODAL } from '../../../core/directives';
import { environment } from '../../../../environments/environment';
import { ArticlesService } from '../core/ArticlesService';
import { IArticleVariant } from '../../../core/interfaces';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../../core/services';
import * as moment from 'moment';

@Component({
  selector: 'app-change-mhd-modal',
  templateUrl: './change-mhd-modal.component.html',
  styleUrl: './change-mhd-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: ChangeMhdModalComponent }]
})
export class ChangeMhdModalComponent extends ModalGeneric {

  public isLoading = signal(false);
  public articleVariant: IArticleVariant;
  public newMhd: string;
  public readonly dateFormat = environment.defaultDateFormat;

  constructor(
    protected cdr: ChangeDetectorRef,
    private readonly _articlesService: ArticlesService,
    private readonly _notificationService: NotificationService,
  ) {
    super(cdr);
  }

  public onSearchChanged(ean: string): void {
    this.isLoading.set(true);

    this._articlesService.getArticleByEan(ean)
      .pipe(
        finalize(() => this.isLoading.set(false))
      )
      .subscribe({
        next: value => {
          this.articleVariant = value;
          this.newMhd = this.articleVariant.mhd;
        },
        error: () => this._notificationService.pushError('Fehler', 'Fehler beim Laden des Artikels.')
      });
  }

  public saveNewMhd(): void {
    this.isLoading.set(true);

    this._articlesService.updateMHD(
      this.articleVariant.id,
      moment(this.newMhd).format('YYYY-MM-DD')
    ).pipe(
      finalize(() => this.isLoading.set(false))
    ).subscribe(() => {
      this.articleVariant.mhd = this.newMhd;
      this._notificationService.pushSuccess('Erfolg', 'MHD korrekt aktualisiert.');
    }, () => {
      this._notificationService.pushError('Fehler', 'Fehler beim Aktualisieren der MHD.');
    });
  }

  public handleCancel(): void {
    this.articleVariant = null;
    this.newMhd = null;
    super.handleCancel();
  }
}
