import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef, HostListener, inject, OnDestroy,
  OnInit, Renderer2, ViewChild,
} from '@angular/core';
import { NotificationService } from '../../core/services';
import { GenericService } from '../../core/services/GenericService';
import { ITableResults } from '../../core/interfaces';
import { NzTableComponent, NzTableQueryParams } from 'ng-zorro-antd/table';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { KeepStateDirective } from '../../core/directives';
import { BaseServiceErrorResolverService } from '../../core/services/error-resolvers/base-service-error-resolver.service';
import { ExportTableModalComponent } from '../modals/export-table-modal/export-table-modal.component';
import { IExportTable } from '../../pages/bulk-containers/core/interfaces/IExportTable';
import { finalize } from 'rxjs/operators';
import { FilterOperator } from '../../core/types';

@Component({
  template: ''
})
export abstract class ListComponentGeneric<T> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('tableRef') tableRef: NzTableComponent<T>;
  @ViewChild('exportTableModalComponent') exportTableModalComponent: ExportTableModalComponent;
  @ViewChild(KeepStateDirective, {static: true}) keepStateDirective: KeepStateDirective;

  public data: ITableResults<T> = {
    results: [],
    info: {
      page: 1,
      results: 0,
      totalResults: 0,
      exportTableName: null
    }
  };
  protected subscriptions: Subscription[] = [];
  public loading = false;
  public pageSize = environment.defaultResultsPerPage;
  public checked = false;
  public indeterminate = false;
  public setOfCheckedId = new Set<number>();
  protected searchColumns: string[]|null = null;
  public tableMaxWidth: string;
  public currentSearch = '';
  protected pageWrapperEl: HTMLElement;
  protected predefindedFilters: { key: string; value: string[]; operator?: FilterOperator; }[] = [];
  protected readonly abstract exportedTableVisibleName: string;
  protected readonly defaultSorting: { sortField: string; sortOrder: string; };
  protected lastLoadParams: {
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
    additionalFilters: { key: string; value: string[] }[]
  } = {
    pageIndex: null,
    pageSize: null,
    sortField: null,
    sortOrder: null,
    filter: [],
    search: [],
    additionalFilters: []
  };
  protected readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  protected readonly renderer2 = inject(Renderer2);

  protected constructor(
    protected _listService: GenericService<T>,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: BaseServiceErrorResolverService,
  ) { }

  @HostListener('window:resize')
  onResize() {
    this._setTableMaxWidth();
  }

  ngOnInit(): void {
    if (this.keepStateDirective && this.keepStateDirective.getValue() !== '') {
      const { pageIndex, pageSize, search } = this.keepStateDirective.getValue();
      this.data.info.page = pageIndex;
      this.pageSize = pageSize;
      this.currentSearch = search[0]?.value[0] ?? '';
    }
  }

  ngAfterViewInit(): void {
    this.pageWrapperEl = this.renderer2.selectRootElement('.ant-layout.nz-layout-wrapper', true);
    this._setTableMaxWidth();

    if (window.innerWidth < 768) {
      this.tableRef.nzPaginationType = 'small';
    }

    this.tableRef.nzCurrentPageDataChange.subscribe(() => {
      this.pageWrapperEl.scrollTo(0, 0);

      setTimeout(() => {
        const tableHeadings = Array.from(this.elementRef.nativeElement.querySelectorAll<HTMLTableCellElement>('th'))
          .map(th => th.textContent);

        this.elementRef.nativeElement.querySelectorAll<HTMLTableCellElement>('td').forEach(td => {
          td.setAttribute('data-name', tableHeadings[td.cellIndex]);
        });
      });
    });
  }

  private _setTableMaxWidth(): void {
    setTimeout(() => this.tableMaxWidth = this.renderer.selectRootElement('nz-table', true).offsetWidth + 'px');
  }

  onRefreshList(defValues = true): void {
    if (defValues) {
      this.loadDefault();
      return;
    }
    const { pageIndex, pageSize, filter, sortField, sortOrder, search } = this.lastLoadParams;
    this.load(pageIndex, pageSize, sortField, sortOrder, filter, search);
    this._cdr.detectChanges();
  }

  public exportBtnClicked(): void {
    const { sortField, sortOrder, filter, additionalFilters } = this.lastLoadParams;
    const tableRef = this.tableRef as any;
    const tableParams: IExportTable = {
      visibleName: this.exportedTableVisibleName,
      fields: Array.from(tableRef.elementRef.nativeElement.querySelectorAll('th')).map((el: HTMLElement) => {
        return {
          label: el.textContent,
          value: el.getAttribute('nzcolumnkey') || el.getAttribute('data-nzColumnKey')
        };
      }).filter(({ label, value }) => label !== '' && value),
      search: this.assignSearch(this.currentSearch).map(({ key, value }) => `${ key }:${ value }`),
      sortField,
      sortOrder,
      filter,
      exportTableName: this.data.info.exportTableName,
      properties: {},
      additionalFilters
    };
    this.exportTableModalComponent.showModal(tableParams);
  }

  protected loadDefault() {
    this.currentSearch = '';
    this.load(1 , this.pageSize, null, null, [], []);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    const filter = params.filter;

    const search = this.assignSearch(this.currentSearch);
    if (pageSize !== this.lastLoadParams.pageSize ||
      (pageIndex !== this.lastLoadParams.pageIndex && pageIndex > 0) ||
      sortField !== this.lastLoadParams.sortField ||
      sortOrder !== this.lastLoadParams.sortOrder ||
      (filter.length > 0  && this.lastLoadParams.filter.length > 0 && filter !== this.lastLoadParams.filter)
    ) {
      this.load(pageIndex, pageSize, sortField, sortOrder, filter, search, this.lastLoadParams.additionalFilters);
    }
  }

  load(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
    additionalFilters: { key: string; value: string[] }[] = [],
  ): void {
    if (!sortField && !sortOrder && this.defaultSorting) {
      sortField = this.defaultSorting.sortField;
      sortOrder = this.defaultSorting.sortOrder;
    }

    this.lastLoadParams = {
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      search,
      additionalFilters
    };
    filter = [...filter, ...this.predefindedFilters];
    if (this.keepStateDirective) {
      this.keepStateDirective.item = {
        ...this.lastLoadParams
      };
    }
    this.callService(pageIndex, pageSize, sortField, sortOrder, filter, search, additionalFilters);
  }

  callService( pageIndex: number,
               pageSize: number,
               sortField: string | null,
               sortOrder: string | null,
               filters: Array<{ key: string; value: string[]; operator?: FilterOperator; }>,
               search: Array<{ key: string; value: string[]; operator?: FilterOperator; }>,
               additionalFilters: Array<{ key: string; value: string[]; operator?: FilterOperator; }>,
  ) {
    this.loading = true;
    this._cdr.detectChanges();

    this._listService.getQueryableAll(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters).subscribe(res => {
      this.loading = false;
      this.data = res;
      this.onListDataAvailable();
      this._cdr.detectChanges();
    }, this.parseError);
  }

  public onListDataAvailable(): void {}

  parseError = data => {
    this._notifyService.pushError(undefined, this._errorResolver.getMessage((data && data.InternalCode) ? data.InternalCode : ''));
  }

  deleteElement = (id: number) => {
    this.loading = true;
    this._listService.delete(id.toString()).pipe(finalize(() => this.loading = false)).subscribe(() => {
      this.onRefreshList(false);
    }, this.parseError);
  }

  search = (value: string) => {
    this.currentSearch = value;
    if (this.searchColumns === null || this.searchColumns === undefined) {
      this.buildSearchColumns();
    }

    this.load(1,
      this.lastLoadParams.pageSize,
      null,
      null,
      [],
      this.assignSearch(value));
  }

  private buildSearchColumns() {
    this.searchColumns = (this.tableRef.nzData.length > 0 ? Object.keys(this.tableRef.nzData[0]) : []);
  }

  private assignSearch(value) {
    if (value.trim() === '') {
      return [];
    }
    return this.searchColumns.map((el) => ({ key: el, value: [value] }));
  }

  protected updateCheckedSet(id: number, checked: boolean): void {
    checked ? this.setOfCheckedId.add(id) : this.setOfCheckedId.delete(id);
  }

  protected refreshCheckedStatus(): void {
    this.checked = this.data.results.every((item: any) => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.data.results.some((item: any) => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  public onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  public onAllChecked(value: boolean): void {
    this.data.results.forEach((item: any) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
