<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <button nz-button nzType="primary" class="clear-cache push-to-left" (click)="clearCache()">Cache leeren / Sync</button>
  <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar>
</app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  appKeepState
  id="articles-overview-list-table"
>
  <thead>
  <tr>
    <th nzColumnKey="id" [nzSortFn]="true" nzWidth="75px">#ID</th>
    <th nzColumnKey="name" [nzSortFn]="true">Name</th>
    <th nzColumnKey="orderNumber" [nzSortFn]="true">Nummer</th>
    <th nzColumnKey="isBio" [nzSortFn]="true">Bio</th>
    <th nzColumnKey="isOwnProduction" [nzSortFn]="true">Eigenproduktion</th>
    <th nzColumnKey="isGiftbox" [nzSortFn]="true">Geschenkset</th>
    <th nzRight nzWidth="70px"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let article of data.results">
    <td>{{article.id}}</td>
    <td>{{article.name}}</td>
    <td>{{article.orderNumber}}</td>
    <td>{{article.isBio | booleanFormat}}</td>
    <td>{{article.isOwnProduction | booleanFormat}}</td>
    <td>{{article.isGiftbox | booleanFormat}}</td>
    <td nzRight>
      <div class="actions">
        <app-add-edit-article-modal (refresh)="onRefreshList(false)" #modal>
        <span trigger>
            <i nz-icon nzType="edit" nzTheme="outline" (click)="openModal(article)"></i>
        </span>
        </app-add-edit-article-modal>
<!--        <span>-->
<!--          <i nz-icon nzType="delete" nzTheme="outline" (click)="deleteElement(article.id)"></i>-->
<!--        </span>-->
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
