<app-inner-box>
  <app-subpage-header title="Übersicht"></app-subpage-header>
<!--  <app-add-edit-modal-->
<!--    (refresh)="listComponent.onRefreshList()"-->
<!--    (articleCreated)="listComponent.openModal($event, 300)"-->
<!--  ></app-add-edit-modal>-->
  <br>
  <app-list #listComponent></app-list>
</app-inner-box>

