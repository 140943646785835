import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef, ViewContainerRef, Output, EventEmitter, Input
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { QrcodeScannerComponent } from '../qrcode-scanner/qrcode-scanner.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, AfterViewInit {

  @ViewChild('inputElement') inputElement: ElementRef<HTMLElement>;

  @Input() clearCodeAfterChange = true;
  @Input() showBarcodeScanner = true;

  @Output() searchChanged = new EventEmitter<string>();

  public code = '';
  private readonly _searchChanged$ = new Subject<string>();

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _modal: NzModalService,
    private readonly _viewContainerRef: ViewContainerRef,
  ) {
  }

  public ngOnInit(): void {
    this._searchChanged$.pipe(debounceTime(1000)).subscribe(code => {
      this.searchChanged.emit(code);

      if (this.clearCodeAfterChange) {
        this.code = '';
      }
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.inputElement.nativeElement.focus(), 300);
  }

  public createBarcodeScannerModal(): void {
    const modal = this._modal.create({
      nzTitle: 'Barcode scanner',
      nzContent: QrcodeScannerComponent,
      nzViewContainerRef: this._viewContainerRef,
      nzWidth: 689,
      nzFooter: [
        {
          label: 'Ok',
          type: 'primary',
          onClick: () => modal.destroy()
        }
      ]
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => {
      if (result && result.code) {
        this.code = result.code;
        this.valueChanged();
      }
      this._cdr.detectChanges();
    });
  }

  public valueChanged(): void {
    if (this.code && this.code !== '') {
      environment.shopUrls.forEach(url => this.code = this.code.replace(`${ url }?voucher=`, ''));
      this.code = this.code.trim();

      this._searchChanged$.next(this.code);
    }
  }

  public clearValue(): void {
    this.code = '';
    this._cdr.detectChanges();
  }
}

