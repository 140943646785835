import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, signal } from '@angular/core';
import { ModalGeneric } from '../../../ui/generics';
import { D_GLOBAL_MODAL } from '../../../core/directives';
import { StoresService } from '../../stores/core/services/StoresService';
import { Observable } from 'rxjs';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { StoreStocksService } from '../core/services/StoreStocks.service';
import { IArticleVariant } from '../../../core/interfaces';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-article-variants-stocks-preview-modal',
  templateUrl: './article-variants-stocks-preview-modal.component.html',
  styleUrl: './article-variants-stocks-preview-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: ArticleVariantsStocksPreviewModalComponent }]
})
export class ArticleVariantsStocksPreviewModalComponent extends ModalGeneric implements OnInit {

  public data: IArticleVariant[] = [];
  public readonly stores$: Observable<ISimpleListItem[]>;
  public readonly isLoading = signal(false);

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private readonly _storeService: StoresService,
    private readonly _storeStocksService: StoreStocksService<any>,
  ) {
    super(cdr);
    this.stores$ = this._storeService.getSimple();
  }

  public showModal(object: any = null): void {
    this.data = [];
    super.showModal(object);
  }

  public onSearchChanged(val: string): void {
    this.isLoading.set(true);

    this._storeStocksService.getAllStoreStocksByArticleName(val)
      .pipe(
        finalize(() => this.isLoading.set(false))
      )
      .subscribe({
        next: res => this.data = res
      });
  }
}
