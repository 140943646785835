import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScannerService {

  public isMainScannerEnabled = true;
  public readonly scannedVal$ = new Subject<string>();
}
