import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ListComponentGeneric } from '../../../../ui/generics';
import { IBulkContainerArticle } from '../../core/interfaces/IBulkContainerArticle';
import { BulkContainersService, BulkContainersServiceErrorResolverService } from '../../core/services';
import { NotificationService } from '../../../../core/services';
import { IBulkContainer } from '../../core/interfaces/IBulkContainer';
import { ITableResults } from '../../../../core/interfaces';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { SuppliersService } from '../../../suppliers/core/services/SuppliersService';
import { ISimpleListItem } from '../../../../core/interfaces/ISimpleListItem';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticlesListComponent extends ListComponentGeneric<IBulkContainer> implements OnInit {

  public isSuppliersLoading = false;
  public searchSupplierChange$ = new Subject();
  public selectedLastSuppliers: ISimpleListItem[] = [];
  public suppliersList: ISimpleListItem[];
  public showArticlesBelowMinStock = false;
  // @ts-ignore
  public data: ITableResults<IBulkContainerArticle> = {
    results: [],
    info: {
      page: 1,
      results: 0,
      totalResults: 0,
      exportTableName: null
    }
  };

  searchColumns = [
    'article.id',
    'article.orderNumber',
    'article.name',
    'bulkContainerState',
    'orderDate',
    'orderSupplier.name',
    'orderQuantitySum',
  ];

  protected readonly exportedTableVisibleName = 'Articles list';

  constructor(
    protected _service: BulkContainersService,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: BulkContainersServiceErrorResolverService,
    private readonly _supplierService: SuppliersService,
) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const getSuppliers = (name: string) =>
      this._supplierService.getSimpleList(name);

    const suppliersOptionList$: Observable<ISimpleListItem[]> = this.searchSupplierChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getSuppliers));
    suppliersOptionList$.subscribe(data => {
      this.suppliersList = data;
      this.isSuppliersLoading = false;
      this._cdr.detectChanges();
    });
  }

  public onSearchSupplier(value: string): void {
    this.isSuppliersLoading = true;
    this.searchSupplierChange$.next(value);
  }

  load(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
    additionalFilters: { key: string; value: string[] }[] = [],
  ): void {
    filter = [
      ...filter,
      ...this.selectedLastSuppliers.map(x => ({
        key: 'supplier.name',
        value: [x.value]
      })),
    ];

    if (this.showArticlesBelowMinStock) {
      filter = [
        { key: 'isBelowMinStock', value: ['true'] },
        ...filter,
      ];
    }

    if (!sortField) {
      sortField = 'article.id';
    }

    if (!sortOrder) {
      sortOrder = 'descend';
    }

    this.lastLoadParams = {
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      search,
      additionalFilters
    };

    if (this.keepStateDirective) {
      this.keepStateDirective.item = {
        ...this.lastLoadParams
      };
    }

    this.loading = true;

    this._service.getQueryableArticlesAll(pageIndex, pageSize, sortField, sortOrder, filter, search, additionalFilters).pipe(
    ).subscribe(res => {
        this.loading = false;
        this.data = res;
        this._cdr.detectChanges();
      }, this.parseError);
  }

  public compareSimpleList(val1: ISimpleListItem, val2: ISimpleListItem): boolean {
    return val1?.id?.toString() === val2?.id?.toString();
  }
}
