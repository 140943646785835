import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services';
import { Observable } from 'rxjs';
import { IOpenOrder } from '../interfaces/IOpenOrder';
import { IGenerateOrderOperation } from '../interfaces/IGenerateOrderOperation';
import { IGenerateBatchOperation } from '../interfaces/IGenerateBatchOperation';
import { IDeliveryNote, IGenerateDeliverySlips } from '../interfaces';
import { HttpParams } from '@angular/common/http';
import { FilterOperator } from '../../../../core/types';


@Injectable({
  providedIn: 'root'
})
export class OpenOrdersService {
  protected readonly baseUrl = '/shopopenorders';

  constructor(public apiService: ApiService) {}

  getOpenOrders(
    status = null,
    numberOfPositions: { value: number; operator: FilterOperator; },
    storageRange: { start: string; end: string; },
  ): Observable<IOpenOrder[]> {
    let params = new HttpParams().append('status', `${status}`);

    if (numberOfPositions) {
      params = params
        .append('numberOfPositions', numberOfPositions.value)
        .append('operator', numberOfPositions.operator);
    }

    if (storageRange.start && storageRange.end) {
      params = params.append('storageRange', `${storageRange.start},${storageRange.end}`);
    }

    return this.apiService.get(`${this.baseUrl}`, (status !== null ? params : null ));
  }

  generateDocuments(orders: IGenerateOrderOperation[]): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/generate-documents`, orders );
  }

  generateDeliverySlips(orders: IGenerateDeliverySlips[]): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/generate-delivery-slip`, orders );
  }

  generateBatchDocument(batch: IGenerateBatchOperation): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/generate-batch-document`, batch );
  }

  getDeliveryNoteByOrderNumber(orderNumber: string): Observable<IDeliveryNote> {
    return this.apiService.get(`${this.baseUrl}/${orderNumber}/by-number`);
  }

  finishOpenOrder(orderId: string): Observable<IDeliveryNote> {
    return this.apiService.post(`${this.baseUrl}/${orderId}/finish`);
  }

  setUncompletedOrder(orderId: string, reason: string): Observable<IDeliveryNote> {
    const params = new HttpParams()
      .append('reason', `${reason}`);
    return this.apiService.post(`${this.baseUrl}/${orderId}/set-uncompleted`, {},  params);
  }

  refreshDeliveryNote(orderId: string): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/${ orderId }/refresh-delivery-note`);
  }
}
